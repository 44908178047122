import React, { Component } from "react";
import Lang from "../../lang";

export default class Newsletter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
    };
  }

  componentDidMount() {
    let _lang = localStorage.getItem("marsellaLang")
    let _langu = localStorage.getItem("marsellaLangu")
    if (_lang && _langu) {
      this.setState({ language: _lang, changeLang: _langu})
    } else {
      localStorage.setItem("marsellaLang", Lang().default)
      localStorage.setItem("marsellaLangu", Lang().btnDefault)
      this.setState({ language: Lang().default, changeLang: Lang().btnDefault})
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    // Create the new request
    var xhr = new XMLHttpRequest();
    var url =
      "https://api.hsforms.com/submissions/v3/integration/submit/19622856/f52bf873-abc7-4fef-a7a1-f7035aef9c5a";

    // Example request JSON:
    var data = {
      submittedAt: Date.now(),
      fields: [
        {
          name: "email",
          value: this.state.email,
        },
      ],
    };

    var final_data = JSON.stringify(data);

    xhr.open("POST", url);
    // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
    xhr.setRequestHeader("Content-Type", "application/json");

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4 && xhr.status == 200) {
        alert(xhr.responseText); // Returns a 200 response if the submission is successful.
      } else if (xhr.readyState == 4 && xhr.status == 400) {
        alert(xhr.responseText); // Returns a 400 error the submission is rejected.
      } else if (xhr.readyState == 4 && xhr.status == 403) {
        alert(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.
      } else if (xhr.readyState == 4 && xhr.status == 404) {
        alert(xhr.responseText); //Returns a 404 error if the formGuid isn't found
      }
    };

    // Sends the request

    xhr.send(final_data);
    this.resetForm();
  };

  resetForm() {
    this.setState({ email: "" });
  }
  onEmailChange(event) {
    this.setState({ email: event.target.value });
  }
  render() {
    return (
      <form onSubmit={this.handleSubmit.bind(this)} method="POST">
        <input
          required
          type="email"
          name="email"
          placeholder={Lang().landing.boletin[this.state.language]}
          value={this.state.email}
          onChange={this.onEmailChange.bind(this)}
        />
        <button type="submit">
          <i className="la la-paper-plane"></i>
        </button>
      </form>
    );
  }
}
