import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class JuniorSm extends Component {
  render() {
    return (
      <li>
        <div className="wd-post">
          <div className="wd-post-img">
            <img
              src="assets/img/jr-suite/jr-suite-hermosillo-mini.jpg"
              alt=""
            />
          </div>
          <div className="wd-post-info">
            <h3>
              <Link to="/junior-suite">Junior Suite</Link>
            </h3>
            <Link to="/junior-suite">
              Reservar Ahora<i className="la la-arrow-right"></i>
            </Link>
          </div>
        </div>
      </li>
    );
  }
}
export default JuniorSm;
