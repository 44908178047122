import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Video from '../common/Video';
import AmenidadesA from '../common/AmenidadesA';
import Testimonios from '../common/Testimonios';
import { Helmet } from 'react-helmet';
import Slider from 'react-slick';
import Flatpickr from 'react-flatpickr';

const today = new Date();
const monthNames = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre'
];
const month = monthNames[today.getMonth()];
class Home extends Component {
  componentWillMount() {
    function loadScript(src) {
      return new Promise(function(resolve, reject) {
        var script = document.createElement('script');
        script.src = src;
        script.addEventListener('load', function() {
          resolve();
        });
        script.addEventListener('error', function(e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }
    loadScript('/assets/js/handleCounter.js');
    loadScript('/assets/js/flatpickr.js');
    loadScript('/assets/revolution/js/jquery.themepunch.tools.min.js');
    loadScript('/assets/revolution/js/jquery.themepunch.revolution.min.js');

    loadScript('/assets/revolution/js/revolution.extension.actions.min.js');
    loadScript('/assets/revolution/js/revolution.extension.carousel.min.js');
    loadScript('/assets/revolution/js/revolution.extension.kenburn.min.js');
    loadScript(
      '/assets/revolution/js/revolution.extension.layeranimation.min.js'
    );
    loadScript('/assets/revolution/js/revolution.extension.migration.min.js');
    loadScript('/assets/revolution/js/revolution.extension.navigation.min.js');
    loadScript('/assets/revolution/js/revolution.extension.parallax.min.js');
    loadScript('/assets/revolution/js/revolution.extension.slideanims.min.js');
    loadScript('/assets/revolution/js/revolution.extension.video.min.js');
    loadScript('/assets/revolution/js/revolution.initialize.js');
    loadScript('/assets/revolution/js/revolution.initialize10.js');
    loadScript('/assets/js/script.js');
  }
  render() {
    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: true,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 572,
          settings: {
            slidesToShow: 1,
            arrows: false
          }
        }
      ]
    };
    return (
      <div className="layout hpg1">
        <Helmet>
          <meta
            name="description"
            content="Marsella 45 Casa Abeyta. Hotel Boutique de lujo en el corazón de Hermosillo. Vive una experiencia única. Disfruta tu estadia mejor que en casa. Reserva Ya!"
          />
          <title>
            Hotel Marsella 45 Casa Abeyta - Excelencia en Hospedaje | Hermosillo
          </title>
        </Helmet>
        <section className="main-slider">
          <div
            id="rev_slider_476_1_wrapper"
            className="rev_slider_wrapper fullscreen-container"
            data-alias="christmas-snow-scene"
            data-source="gallery"
            style={{ backgroundColor: 'transparent', padding: '0px' }}
          >
            <div
              id="rev_slider_476_1"
              className="rev_slider"
              style={{ display: 'none' }}
              data-version="5.3.0.2"
            >
              <ul>
                <li
                  className="scnd_slide"
                  data-index="rs-1648"
                  data-transition="fade"
                  data-slotamount="default"
                  data-hideafterloop="0"
                  data-hideslideonmobile="off"
                  data-easein="default"
                  data-easeout="default"
                  data-masterspeed="1000"
                  data-thumb="assets/images/slider_banner.jpg"
                  data-rotate="0"
                  data-saveperformance="off"
                  data-title="Slide"
                  data-param1=""
                  data-param2=""
                  data-param3=""
                  data-param4=""
                  data-param5=""
                  data-param6=""
                  data-param7=""
                  data-param8=""
                  data-param9=""
                  data-param10=""
                  data-description=""
                >
                  <img
                    src="/assets/img/slider-1.jpg"
                    alt=""
                    data-bgposition="center center"
                    data-kenburns="on"
                    data-duration="10000"
                    data-ease="Linear.easeNone"
                    data-scalestart="100"
                    data-scaleend="105"
                    data-rotatestart="0"
                    data-rotateend="0"
                    data-blurstart="0"
                    data-blurend="0"
                    data-offsetstart="0 0"
                    data-offsetend="0 0"
                    className="rev-slidebg"
                    data-no-retina
                  />

                  <div
                    className="tp-caption lyr1 tp-resizeme"
                    id="slide-1-layer-1"
                    data-x="center"
                    data-hoffset="0"
                    data-y="center"
                    data-voffset="0"
                    data-width="['auto','auto','auto','auto']"
                    data-height="['auto','auto','auto','auto']"
                    data-paddingtop="[15,15,15,15]"
                    data-paddingright="[40,40,40,40]"
                    data-paddingbottom="[15,15,15,15]"
                    data-paddingleft="[40,40,40,40]"
                    data-transform_idle="o:1;"
                    data-transform_in="y:50px;opacity:0;s:1500;e:Power3.easeOut;"
                    data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;"
                    data-start="200"
                    data-splitin="none"
                    data-splitout="none"
                    data-responsive_offset="on"
                    data-elementdelay="0.05"
                    style={{ bottom: '40px' }}
                  >
                    Casa Abeyta
                  </div>

                  <div
                    className="tp-caption lyr2 tp-resizeme"
                    id="slide-1-layer-2"
                    data-x="center"
                    data-hoffset="0"
                    data-y="center"
                    data-voffset="73"
                    data-width="['auto','auto','auto','auto']"
                    data-height="['auto','auto','auto','auto']"
                    data-paddingtop="[15,15,15,15]"
                    data-paddingright="[40,40,40,40]"
                    data-paddingbottom="[15,15,15,15]"
                    data-paddingleft="[40,40,40,40]"
                    data-transform_idle="o:1;"
                    data-transform_in="y:50px;opacity:0;s:1500;e:Power3.easeOut;"
                    data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;"
                    data-start="200"
                    data-splitin="none"
                    data-splitout="none"
                    data-responsive_offset="on"
                    data-elementdelay="0.05"
                    style={{ bottom: '40px' }}
                  >
                    <img src="/assets/img/marsella-logo-slider.png" alt="" />
                  </div>

                  <Link
                    to="/suites"
                    title=""
                    className="tp-caption lyr3 hv2 tp-resizeme"
                    id="slide-1-layer-3"
                    data-x="center"
                    data-hoffset="0"
                    data-y="center"
                    data-voffset="164"
                    data-width="['auto','auto','auto','auto']"
                    data-height="['auto','auto','auto','auto']"
                    data-paddingtop="[29,29,29,29]"
                    data-paddingright="[37,37,37,37]"
                    data-paddingbottom="[27,27,27,27]"
                    data-paddingleft="[37,37,37,37]"
                    data-transform_idle="o:1;"
                    data-transform_in="y:50px;opacity:0;s:1500;e:Power3.easeOut;"
                    data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;"
                    data-start="500"
                    data-splitin="none"
                    data-splitout="none"
                    data-responsive_offset="on"
                    data-elementdelay="0.05"
                    style={{ bottom: '40px' }}
                  >
                    SUITES
                  </Link>
                </li>

                <li
                  data-index="rs-1649"
                  className="scnd_slide"
                  data-transition="fade"
                  data-slotamount="default"
                  data-hideafterloop="0"
                  data-hideslideonmobile="off"
                  data-easein="default"
                  data-easeout="default"
                  data-masterspeed="1000"
                  data-thumb="assets/images/slider_banner.jpg"
                  data-rotate="0"
                  data-saveperformance="off"
                  data-title="Slide"
                  data-param1=""
                  data-param2=""
                  data-param3=""
                  data-param4=""
                  data-param5=""
                  data-param6=""
                  data-param7=""
                  data-param8=""
                  data-param9=""
                  data-param10=""
                  data-description=""
                >
                  <img
                    src="/assets/img/slider-2.jpg"
                    alt=""
                    data-bgposition="center center"
                    data-kenburns="on"
                    data-duration="10000"
                    data-ease="Linear.easeNone"
                    data-scalestart="100"
                    data-scaleend="105"
                    data-rotatestart="0"
                    data-rotateend="0"
                    data-blurstart="0"
                    data-blurend="0"
                    data-offsetstart="0 0"
                    data-offsetend="0 0"
                    className="rev-slidebg"
                    data-no-retina
                  />

                  <div
                    className="tp-caption lyr1 tp-resizeme"
                    id="slide-1-layer-4"
                    data-x="center"
                    data-hoffset="0"
                    data-y="center"
                    data-voffset="0"
                    data-width="['auto','auto','auto','auto']"
                    data-height="['auto','auto','auto','auto']"
                    data-paddingtop="[15,15,15,15]"
                    data-paddingright="[40,40,40,40]"
                    data-paddingbottom="[15,15,15,15]"
                    data-paddingleft="[40,40,40,40]"
                    data-transform_idle="o:1;"
                    data-transform_in="y:50px;opacity:0;s:1500;e:Power3.easeOut;"
                    data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;"
                    data-start="200"
                    data-splitin="none"
                    data-splitout="none"
                    data-responsive_offset="on"
                    data-elementdelay="0.05"
                    style={{ bottom: '40px' }}
                  >
                    Hotel Marsella 45
                  </div>

                  <div
                    className="tp-caption lyr2 tp-resizeme"
                    id="slide-1-layer-5"
                    data-x="center"
                    data-hoffset="0"
                    data-y="center"
                    data-voffset="73"
                    data-width="['auto','auto','auto','auto']"
                    data-height="['auto','auto','auto','auto']"
                    data-paddingtop="[15,15,15,15]"
                    data-paddingright="[40,40,40,40]"
                    data-paddingbottom="[15,15,15,15]"
                    data-paddingleft="[40,40,40,40]"
                    data-transform_idle="o:1;"
                    data-transform_in="y:50px;opacity:0;s:1500;e:Power3.easeOut;"
                    data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;"
                    data-start="200"
                    data-splitin="none"
                    data-splitout="none"
                    data-responsive_offset="on"
                    data-elementdelay="0.05"
                    style={{ bottom: '40px' }}
                  >
                    UNA EXPERIENCIA QUE DEBES VIVIR
                  </div>

                  <Link
                    to="/suites"
                    title=""
                    className="tp-caption lyr3 hv2 tp-resizeme"
                    id="slide-1-layer-3"
                    data-x="center"
                    data-hoffset="0"
                    data-y="center"
                    data-voffset="164"
                    data-width="['auto','auto','auto','auto']"
                    data-height="['auto','auto','auto','auto']"
                    data-paddingtop="[29,29,29,29]"
                    data-paddingright="[37,37,37,37]"
                    data-paddingbottom="[27,27,27,27]"
                    data-paddingleft="[37,37,37,37]"
                    data-transform_idle="o:1;"
                    data-transform_in="y:50px;opacity:0;s:1500;e:Power3.easeOut;"
                    data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;"
                    data-start="500"
                    data-splitin="none"
                    data-splitout="none"
                    data-responsive_offset="on"
                    data-elementdelay="0.05"
                    style={{ bottom: '40px' }}
                  >
                    SUITES
                  </Link>
                </li>

                <li
                  data-index="rs-1650"
                  className="scnd_slide thrd"
                  data-transition="fade"
                  data-slotamount="default"
                  data-hideafterloop="0"
                  data-hideslideonmobile="off"
                  data-easein="default"
                  data-easeout="default"
                  data-masterspeed="1000"
                  data-thumb="assets/images/slider_banner.jpg"
                  data-rotate="0"
                  data-saveperformance="off"
                  data-title="Slide"
                  data-param1=""
                  data-param2=""
                  data-param3=""
                  data-param4=""
                  data-param5=""
                  data-param6=""
                  data-param7=""
                  data-param8=""
                  data-param9=""
                  data-param10=""
                  data-description=""
                >
                  <img
                    src="/assets/img/slider-3.jpg"
                    alt=""
                    data-bgposition="center center"
                    data-kenburns="on"
                    data-duration="10000"
                    data-ease="Linear.easeNone"
                    data-scalestart="100"
                    data-scaleend="105"
                    data-rotatestart="0"
                    data-rotateend="0"
                    data-blurstart="0"
                    data-blurend="0"
                    data-offsetstart="0 0"
                    data-offsetend="0 0"
                    className="rev-slidebg"
                    data-no-retina
                  />

                  <div
                    className="tp-caption lyr1 tp-resizeme"
                    id="slide-1-layer-7"
                    data-x="center"
                    data-hoffset="0"
                    data-y="center"
                    data-voffset="0"
                    data-width="['auto','auto','auto','auto']"
                    data-height="['auto','auto','auto','auto']"
                    data-paddingtop="[15,15,15,15]"
                    data-paddingright="[40,40,40,40]"
                    data-paddingbottom="[15,15,15,15]"
                    data-paddingleft="[40,40,40,40]"
                    data-transform_idle="o:1;"
                    data-transform_in="y:50px;opacity:0;s:1500;e:Power3.easeOut;"
                    data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;"
                    data-start="200"
                    data-splitin="none"
                    data-splitout="none"
                    data-responsive_offset="on"
                    data-elementdelay="0.05"
                    style={{ bottom: '40px' }}
                  >
                     No es suficiente con ser nuevo
                  </div>

                  <div
                    className="tp-caption lyr2 tp-resizeme"
                    id="slide-1-layer-8"
                    data-x="center"
                    data-hoffset="0"
                    data-y="center"
                    data-voffset="73"
                    data-width="['auto','auto','auto','auto']"
                    data-height="['auto','auto','auto','auto']"
                    data-paddingtop="[15,15,15,15]"
                    data-paddingright="[40,40,40,40]"
                    data-paddingbottom="[15,15,15,15]"
                    data-paddingleft="[40,40,40,40]"
                    data-transform_idle="o:1;"
                    data-transform_in="y:50px;opacity:0;s:1500;e:Power3.easeOut;"
                    data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;"
                    data-start="200"
                    data-splitin="none"
                    data-splitout="none"
                    data-responsive_offset="on"
                    data-elementdelay="0.05"
                    style={{ bottom: '40px' }}
                  >
                    SIMPLEMENTE NO LO VAS A CREER
                  </div>

                  <Link
                    to="/suites"
                    title=""
                    className="tp-caption lyr3 hv2 tp-resizeme"
                    id="slide-1-layer-3"
                    data-x="center"
                    data-hoffset="0"
                    data-y="center"
                    data-voffset="164"
                    data-width="['auto','auto','auto','auto']"
                    data-height="['auto','auto','auto','auto']"
                    data-paddingtop="[29,29,29,29]"
                    data-paddingright="[37,37,37,37]"
                    data-paddingbottom="[27,27,27,27]"
                    data-paddingleft="[37,37,37,37]"
                    data-transform_idle="o:1;"
                    data-transform_in="y:50px;opacity:0;s:1500;e:Power3.easeOut;"
                    data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;"
                    data-start="500"
                    data-splitin="none"
                    data-splitout="none"
                    data-responsive_offset="on"
                    data-elementdelay="0.05"
                    style={{ bottom: '40px' }}
                  >
                    SUITES
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <form
            action="https://app.thebookingbutton.com/properties/marsella45direct"
            className="ibe"
            data-region="emea"
            data-channelcode="marsella45direct"
            target="_blank"
          >
            <div className="checking-form sldr shugert-checking-desktop">
              <ul className="checkform">
                <li>
                  <div className="check-form">
                    <h4>CHECK-IN</h4>
                    <Flatpickr
                      value={new Date()}
                      onChange={(_, str) => console.info(str)}
                      name="check_in_date"
                    />
                    <div className="clearfix"></div>
                  </div>
                </li>
                <li className="item-cont">
                  <div className="check-form">
                    <h4 className="text-center">Noches</h4>
                    <div className="det">
                      <div className="handle-counter" id="handleCounter2">
                        <ul>
                          <li className="input-fieldd">
                            <input
                              name="number_nights"
                              type="text"
                              min="1"
                              value="1"
                            />
                          </li>
                          <li className="counter-control">
                            <a className="counter-plus btn">
                              <i className="fa fa-angle-up"></i>
                            </a>
                            <a className="counter-minus btn " disabled="">
                              <i className="fa fa-angle-down"></i>
                            </a>
                          </li>
                        </ul>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                    <div className="clearfix"></div>
                  </div>

                  <a href="#" title="" className="arw-down">
                    <i className="fa fa-angle-down"></i>
                  </a>
                </li>

                <li className="item-cont">
                  <div className="check-form">
                    <h4 className="text-center">Huespedes</h4>
                    <div className="det">
                      <div className="handle-counter" id="handleCounter">
                        <ul>
                          <li className="input-fieldd">
                            <input
                              max="7"
                              min="1"
                              name="number_adults"
                              type="text"
                              value="2"
                            />
                          </li>
                          <li className="counter-control">
                            <a className="counter-plus btn">
                              <i className="fa fa-angle-up"></i>
                            </a>
                            <a className="counter-minus btn " disabled="">
                              <i className="fa fa-angle-down"></i>
                            </a>
                          </li>
                        </ul>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                    <div className="clearfix"></div>
                  </div>

                  <a href="#" title="" className="arw-down">
                    <i className="fa fa-angle-down"></i>
                  </a>
                </li>

                <li className="wuto">
                  <div className="bk_now">
                    <button href="#" title="" type="submit" className="bk-now">
                      Ver Disponibilidad
                    </button>
                  </div>
                  <div className="clearfix"></div>
                </li>
              </ul>
              <div className="clearfix"></div>
            </div>
          </form>

          <form
            action="#"
            className="ibe"
            data-region="emea"
            data-channelcode="marsella45direct"
            target="_blank"
          >
            <div className="checking-form sldr shugert-checking-mobile">
              <ul className="checkform">
                <li>
                  <div className="check-form">
                    <h4>CHECK-IN</h4>
                    <Flatpickr
                      value={new Date()}
                      onChange={(_, str) => console.info(str)}
                      name="check_in_date"
                    />
                    <div className="clearfix"></div>
                  </div>
                </li>
                <li className="item-cont">
                  <div className="check-form">
                    <h4 className="text-center">Noches</h4>
                    <div className="det">
                      <div className="handle-counter" id="handleCounter3">
                        <ul>
                          <li className="input-fieldd">
                            <input
                              name="number_nights"
                              type="text"
                              min="1"
                              value="1"
                            />
                          </li>
                          <li className="counter-control">
                            <a className="counter-plus btn">
                              <i className="fa fa-angle-up"></i>
                            </a>
                            <a className="counter-minus btn " disabled="">
                              <i className="fa fa-angle-down"></i>
                            </a>
                          </li>
                        </ul>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                    <div className="clearfix"></div>
                  </div>

                  <a href="#" title="" className="arw-down">
                    <i className="fa fa-angle-down"></i>
                  </a>
                </li>

                <li className="item-cont">
                  <div className="check-form">
                    <h4 className="text-center">Huespedes</h4>
                    <div className="det">
                      <div className="handle-counter" id="handleCounter4">
                        <ul>
                          <li className="input-fieldd">
                            <input
                              max="7"
                              min="1"
                              name="number_adults"
                              type="text"
                              value="2"
                            />
                          </li>
                          <li className="counter-control">
                            <a className="counter-plus btn">
                              <i className="fa fa-angle-up"></i>
                            </a>
                            <a className="counter-minus btn " disabled="">
                              <i className="fa fa-angle-down"></i>
                            </a>
                          </li>
                        </ul>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                    <div className="clearfix"></div>
                  </div>

                  <a href="#" title="" className="arw-down">
                    <i className="fa fa-angle-down"></i>
                  </a>
                </li>

                <li className="wuto">
                  <div className="bk_now">
                    <button href="#" title="" type="submit" className="bk-now">
                      Ver Disponibilidad
                    </button>
                  </div>
                  <div className="clearfix"></div>
                </li>
              </ul>
              <div className="clearfix"></div>
            </div>
          </form>
        </section>

        <section className="c-block">
          <div className="container">
            <div className="about-us-sec">
              <div className="row">
                <div className="col-lg-5">
                  <div className="about_we pt-0">
                    <h2>MARSELLA 45</h2>
                    <h3>QUIÉNES SOMOS</h3>
                    <p>
                      Marsella 45 emerge en la zona más bella de la ciudad con
                      20 espaciosas Suites que lo han convertido en un referente
                      de lujo y refinamiento de Hermosillo. Cada una de sus
                      Suites, indistintas en decoración contemporánea entre sí,
                      han llegado a innovar el concepto de hospedaje de gran
                      clase.
                    </p>
                    <p>
                      La calidez y atención personalizada en nuestro servicio
                      nos permite ir más allá de una simple bienvenida,
                      acompañamos a nuestros huéspedes durante toda su estancia
                    </p>
                    <Link to="quienes-somos" title="" className="lnk-default">
                      Nosotros <i className="la la-arrow-right"></i>
                    </Link>
                  </div>
                </div>
                <div className="col-lg-7 p-0">
                  <ul className="abt_img">
                    <li>
                      <img src="/assets/img/quienes-somos-home.jpg" alt="" />
                    </li>
                    <li>
                      <img src="/assets/img/quienes-somos2-home.jpg" alt="" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="c-block p-0">
          <div className="container-fluid p-0">
            <div className="rooms-sec">
              <Slider {...settings}>
                <div className="">
                  <div className="room-details">
                    <img src="/assets/img/luxury-suite.jpg" alt="" />
                    <div className="room-hd">
                      <h3>LUXURY SUITE</h3>
                    </div>
                    <Link to="/luxury-suite" className="booking-btn">
                      RESERVA AHORA <i className="la la-arrow-right"></i>
                    </Link>
                  </div>
                </div>
                <div className="">
                  <div className="room-details">
                    <img src="/assets/img/master-suite.jpg" alt="" />
                    <div className="room-hd">
                      <h3>MASTER SUITE</h3>
                    </div>
                    <Link to="master-suite" title="" className="booking-btn">
                      RESERVA AHORA <i className="la la-arrow-right"></i>
                    </Link>
                  </div>
                </div>
                <div className="">
                  <div className="room-details">
                    <img src="/assets/img/jr-suite-doble.jpg" alt="" />
                    <div className="room-hd">
                      <h3>JUNIOR SUITE DOBLE</h3>
                    </div>
                    <Link
                      to="junior-suite-doble"
                      title=""
                      className="booking-btn"
                    >
                      RESERVA AHORA <i className="la la-arrow-right"></i>
                    </Link>
                  </div>
                </div>
                <div className="">
                  <div className="room-details">
                    <img src="/assets/img/jr-suite.jpg" alt="" />
                    <div className="room-hd">
                      <h3>JUNIOR SUITE</h3>
                    </div>
                    <Link to="junior-suite" title="" className="booking-btn">
                      RESERVA AHORA <i className="la la-arrow-right"></i>
                    </Link>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </section>
        <AmenidadesA />
        <Video />
        <Testimonios />
      </div>
    );
  }
}

export default Home;
