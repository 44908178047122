import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Navbar from "./components/layout/Navbar";
import Footer from "./components/layout/Footer";
import Landing from "./components/layout/Landing";
import Home from "./components/layout/Home";
import ScrollToTop from "./components/layout/ScrollToTop";
import NotFound from "./components/pages/NotFound";
import QuienesSomos from "./components/pages/QuienesSomos";
import Servicios from "./components/pages/Servicios";
import Galeria from "./components/pages/Galeria";
import CocinaRustica from "./components/pages/CocinaRustica";
import LuxurySuite from "./components/pages/LuxurySuite";
import MasterSuite from "./components/pages/MasterSuite";
import JuniorSuite from "./components/pages/JuniorSuite";
import JuniorSuiteDoble from "./components/pages/JuniorSuiteDoble";
import Suites from "./components/pages/Suites";
import Pruebas from "./components/pages/Pruebas";

import "./App.css";

class App extends Component {
  render() {
    return (
      <Router>
        <ScrollToTop />
        <div className="App">
          <Navbar />
          <Switch>
            <Route exact path="/" component={Landing} />
            <Route exact path="/quienes-somos" component={QuienesSomos} />
            <Route exact path="/pruebas" component={Pruebas} />
            <Route exact path="/servicios" component={Servicios} />
            <Route exact path="/galeria" component={Galeria} />
            <Route
              exact
              path="/restaurante-quiriego"
              component={CocinaRustica}
            />
            <Route exact path="/luxury-suite" component={LuxurySuite} />
            <Route exact path="/master-suite" component={MasterSuite} />
            <Route exact path="/junior-suite" component={JuniorSuite} />
            <Route exact path="/home" component={Home} />
            <Route exact path="/pruebas" component={Pruebas} />
            <Route exact path="/suites" component={Suites} />
            <Route
              exact
              path="/junior-suite-doble"
              component={JuniorSuiteDoble}
            />
            <Route component={NotFound} />
          </Switch>
          <Footer />
        </div>
      </Router>
    );
  }
}

export default App;
