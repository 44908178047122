import React, { Component } from "react";
import Lightbox from "react-image-lightbox";

class GaleriaMarsella extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photoIndex: 0,
      isOpen: false
    };
  }
  render() {
    const images = [
      "assets/img/hotel/hotel-hermosillo-1.JPG",
      "assets/img/hotel/hotel-hermosillo-2.JPG",
      "assets/img/hotel/hotel-hermosillo-3.JPG",
      "assets/img/hotel/hotel-hermosillo-4.JPG",
      "assets/img/hotel/hotel-hermosillo-5.JPG",
      "assets/img/hotel/hotel-hermosillo-6.JPG",
      "assets/img/hotel/hotel-hermosillo-7.JPG",
      "assets/img/hotel/hotel-hermosillo-8.JPG",
      "assets/img/hotel/hotel-hermosillo-9.JPG",
      "assets/img/hotel/hotel-hermosillo-10.JPG",
      "assets/img/hotel/hotel-hermosillo-11.JPG",
      "assets/img/hotel/hotel-hermosillo-12.JPG"
    ];
    let imageItems = images.map((image, i) => {
      return (
        <div className="col-lg-4 col-md-4 col-sm-6">
          <div className="gd-item">
            <img src={image} alt="" />
            <a
              onClick={() => this.setState({ isOpen: true, photoIndex: i })}
              title=""
              className="html5lightbox zoom-img-3"
            >
              <img src="/assets/images/zoom.png" alt="" />
            </a>
          </div>
        </div>
      );
    });
    const { photoIndex, isOpen } = this.state;
    return (
      <div
        className="tab-pane fade"
        id="tab5"
        role="tabpanel"
        aria-labelledby="tab_5"
      >
        <div className="gallery-grid">
          <div className="row">{imageItems}</div>
        </div>
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length
              })
            }
          />
        )}
      </div>
    );
  }
}
export default GaleriaMarsella;
