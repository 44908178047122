import React, { Component } from "react";
import HubspotForm from "react-hubspot-form";
import { Link } from "react-router-dom";
import Newsletter from "./Newsletter";
import Lang from "../../lang";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
    }; 
  }
  
  componentDidMount() {
    let _lang = localStorage.getItem("marsellaLang")
    let _langu = localStorage.getItem("marsellaLangu")
    if (_lang && _langu) {
      this.setState({ language: _lang, changeLang: _langu})
    } else {
      localStorage.setItem("marsellaLang", Lang().default)
      localStorage.setItem("marsellaLangu", Lang().btnDefault)
      this.setState({ language: Lang().default, changeLang: Lang().btnDefault})
    }

    if (_lang === 'es'){
      this.setState({ linke: "contacto.html"})
    } else {
      this.setState({ linke: "contact.html"})
    }
  }

  render() {
    return (
      <footer>
        <div className="top-strip">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <p>{Lang().landing.footer.descripcion[this.state.language]}</p>
              </div>
              <div className="col-md-6">
                <div className="bola-trabajo-row" style={{ float: "right" }}>
                  <ul>
                    <li>
                      <a href="mailto:rh@marsella45.mx">
                        <button className="bolsa-trabajo">{Lang().landing.footer.contactanos[this.state.language]}</button>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="top-footer">
          <div className="container">
            <div className="ft-logo">
              <img
                className="custom-footer-logo"
                src="/assets/img/marsella-logo-navbar.png"
                alt=""
              />
            </div>

            <div className="social-links">
              <ul>
                <li>
                  <a href="https://www.facebook.com/Marsella45mx/" title="">
                    <i className="fab fa-facebook-square"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/marsella45mx/" title="">
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a href="mailto:reservaciones@marsella45.mx" title="">
                    <i className="fa fa-envelope"></i>
                  </a>
                </li>
              </ul>
            </div>

            <div className="clearfix"></div>
          </div>
        </div>

        <div className="bottom-footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="widget widget-info">
                  <h4>
                    <a
                      onClick="return gtag_report_conversion('tel:662-520-0200');"
                      href="tel:662-520-0200"
                    >
                      (662) 520-0200
                    </a>
                  </h4>
                  <ul className="info-lst">
                    <li>
                      Av. Marsella 45, Colonia Centenario Hermosillo, Sonora
                      C.P. 83260 México
                    </li>
                    <li>
                      <a href={this.state.linke}>{Lang().landing.footer.encuentranos[this.state.language]}</a>
                    </li>
                    <li>reservaciones@marsella45.mx</li>
                  </ul>
                  <ul className="pt-lst">
                    <li>
                      <img src="/assets/img/payment-logo.png" alt="" />
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-6">
                <div className="widget widget-links">
                  <h3 className="widget-title">{Lang().landing.footer.reservaciones[this.state.language]}</h3>
                  <ul>
                    <li>
                      <a href="#" title="">
                        Suites
                      </a>
                    </li>
                    <li>
                      <Link to="/restaurante-quiriego">Quiriego</Link>
                    </li>
                    <li>
                      <Link to="galeria">{Lang().landing.footer.galeria[this.state.language]}</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-6">
                <div className="widget widget-links">
                  <h3 className="widget-title">{Lang().landing.footer.nosotros[this.state.language]}</h3>
                  <ul>
                    <li>
                      <Link to="/quienes-somos">{Lang().landing.footer.quienesSomos[this.state.language]}</Link>
                    </li>
                    <li>
                      <Link to="/servicios">{Lang().landing.footer.servicios[this.state.language]}</Link>
                    </li>
                    <li>
                      <a href="/contacto.html">{Lang().landing.footer.contacto[this.state.language]}</a>
                    </li>
                    <li>
                      <a href="aviso-de-privacidad.pdf" target="_blank">
                      {Lang().landing.footer.avisoPrivacidad[this.state.language]}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="widget widget-newsletter">
                  <h3 className="widget-title">Newsletter</h3>
                  <p>
                  {Lang().landing.footer.recibeNoticias[this.state.language]}
                    
                  </p>
                  <Newsletter />
                  {/* <HubspotForm
                    portalId="19622856"
                    formId="f52bf873-abc7-4fef-a7a1-f7035aef9c5a"
                    cssClass="marsella-newsletter"
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bottom-strip">
          <div className="container">
            <p>
              © 2021 Marsella 45. {Lang().landing.footer.derechosReservados[this.state.language]}{" "}
              <a className="custom-shugert" href="https://shugert.com.mx">
                Web Design
              </a>
              : Shugert Marketing &amp; Analytics
            </p>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
