import React, { Component } from 'react';
import Slider from 'react-slick';
import Lang from "../../lang";

class Testimonios extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
    }; 
  }
  
  componentDidMount() {
    let _lang = localStorage.getItem("marsellaLang")
    let _langu = localStorage.getItem("marsellaLangu")
    if (_lang && _langu) {
      this.setState({ language: _lang, changeLang: _langu})
    } else {
      localStorage.setItem("marsellaLang", Lang().default)
      localStorage.setItem("marsellaLangu", Lang().btnDefault)
      this.setState({ language: Lang().default, changeLang: Lang().btnDefault})
    }
  }

  render() {
    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true
    };
    return (
      <section className="c-block testimonial-sec">
        <div className="container">
          <div className="sec-title">
            <h3>{Lang().landing.testimonios.descripcion[this.state.language]}</h3>
          </div>

          <Slider className="testimonial-carousel" {...settings}>
            <div className="testi">
              <h3>Daniel Z</h3>
              <p>
              {Lang().landing.testimonios.danielZ[this.state.language]}
              </p>
            </div>

            <div className="testi">
              <h3>KetyMar</h3>
              <p>
              {Lang().landing.testimonios.ketyMar[this.state.language]}
              </p>
            </div>

            <div className="testi">
              <h3>Jazz</h3>
              <p>
              {Lang().landing.testimonios.jazz[this.state.language]}
              </p>
            </div>
          </Slider>
        </div>
      </section>
    );
  }
}

export default Testimonios;
