import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export default function NotFound() {
  return (
    <div>
      <Helmet>
        <title>Marsella 45 - Página no encontrada</title>
      </Helmet>

      <section className="pager-sec error-sec">
        <div className="container">
          <div className="pager-sec-details">
            <h3>PÁGINA NO ENCONTRADA</h3>
          </div>
        </div>
      </section>

      <section className="main-content white_bg">
        <div className="container">
          <div className="page-content">
            <div className="error_sec">
              <h1>
                4<span>0</span>4
              </h1>
              <p>Lo sentimos, la página que buscas no existe.</p>

              <Link to="/home">
                Volver al Inicio <i className="la la-arrow-right"></i>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
