import React from 'react';

const texts = {
    default: "en",
    btnDefault: "ESPAÑOL",
    // Genericos
    generico: {
        inicio: {
            es: "Inicio",
            en: "Home"
        },
        noches: {
            es: "Noches",
            en: "Nights"
        },
        huespedes: {
            es: "Huespedes",
            en: "Guests"
        },
        verDisponibilidad: {
            es: "Ver Disponibilidad",
            en: "See availability"
        },
        habitacion: {
            es: "Habitación",
            en: "Room"
        },
        adultos: {
            es: "Adultos",
            en: "Adults"
        },
        ninos: {
            es: "Niños",
            en: "Children"
        },
        o: {
            es: "o",
            en: "or"
        },
        servicios: {
            es: "Servicios",
            en: "Services"
        },
        reservarAhora: {
            es: "Reservar ahorra",
            en: "Book now"
        }
    },
    // Menu principal (Navbar)
    menu: {
        inicio: {
            es: "Inicio",
            en: "Home"
        },
        servicios: {
            es: "Servicios",
            en: "Services"
        },
        contacto: {
            es: "Contacto",
            en: "Contact"
        },
        reservaciones: {
            es: "Reservaciones",
            en: "Reservations"
        },
        galeria: {
            es: "Galería",
            en: "Gallery"
        }
    },

    // Página principal (Landing)
    landing: {
        slider: {
            noEsSuficiente: {
                es: "No es suficiente con ser nuevo",
                en: "It's not enough to be new"
            },
            simplementeNoLoVasACreer: {
                es: "SIMPLEMENTE NO LO VAS A CREER",
                en: "YOU JUST WON'T BELIEVE IT"
            },
            noches: {
                es: "Noches",
                en: "Nights"
            },
            huespedes: {
                es: "Huespedes",
                en: "Guests"
            },
            verDisponibilidad: {
                es: "Ver Disponibilidad",
                en: "See availability"
            }
        },

        // Quienes Somos
        quienesSomos: {
            quienesSomos: {
                es: "QUIÉNES SOMOS",
                en: "ABOUT US"
            },
            parrafo1: {
                es: "Marsella 45 emerge en la zona más bella de la ciudad con 20 espaciosas Suites que lo han convertido en un referente de lujo y refinamiento de Hermosillo. Cada una de sus Suites, indistintas en decoración contemporánea entre sí, han llegado a innovar el concepto de hospedaje de gran clase.",
                en: "Marsella 45 emerges in the most beautiful area of the city with 20 spacious Suites that have made it a benchmark of luxury and refinement in Hermosillo. Each of its Suites, indistinct in contemporary decor, have come to innovate the concept of high-class accommodation."
            },
            parrafo2: {
                es: "La calidez y atención personalizada en nuestro servicio nos permite ir más allá de una simple bienvenida, acompañamos a nuestros huéspedes durante toda su estancia.",
                en: "The warmth and personalized attention in our service allows us to go beyond a simple welcome, we accompany our guests throughout their stay."
            },
            nosotros: {
                es: "Nosotros",
                en: "About"
            },
            reservaAhora: {
                es: "RESERVA AHORA",
                en: "BOOK NOW"
            }
        },

        // Amenidades
        amenidades: {
            nuestrasAmenidades: {
                es: "Nuestras Amenidades",
                en: "Our Amenities"
            },
            alberca: {
                es: "Alberca",
                en: "Swimming Pool"
            },
            albercaDesc: {
                es: "Puedes disfrutar de la alberca y la tranquilidad que te envuelve.",
                en: "You can enjoy the pool and the tranquility that surrounds you."
            },
            restaurante: {
                es: "Restaurante Quiriego",
                en: "Quiriego Restaurant"
            },
            restauranteDesc: {
                es: "Descubre la experiencia Quiriego, donde su cocina rústica y su chef te asombrarán",
                en: "Discover the Quiriego experience, where its rustic cuisine and its chef will amaze you"
            },
            cigarRoomDesc: {
                es: "El lugar que buscas para reuniones de negocios, amigos o familia, cuenta con patio interior, chimenea y un espacio exclusivo dentro de Marsella 45.",
                en: "The place you are looking for business meetings, friends or family, has an interior patio, fireplace and an exclusive space within Marsella 45."
            },
            gimnasio: {
                es: "Gimnasio",
                en: "Gym"
            },
            gimnasioDesc: {
                es: "El gimnasio cuenta equipo de entrenamiento como elíptica, caminadora, bicicleta estacionaria y pesas.",
                en: "The gym has training equipment such as elliptical, treadmill, stationary bike, and weights."
            },
            privado: {
                es: "Privado La Cava",
                en: "Private La Cava"
            },
            privadoDesc: {
                es: "En el corazón de Marsella 45 vive una experiencia sin igual. Rodeado por vinos y un ambiente de alta privacidad, La Cava será tu espacio favorito.",
                en: "In the heart of Marseille 45 live an experience like no other. Surrounded by wines and an environment of high privacy, La Cava will be your favorite space."
            }
        },

        // Video
        video: {
            descripcion: {
                es: "Armonía que invita a despejar la mente",
                en: "Harmony that invites you to clear your mind"
            }
        },

        // Testimonios
        testimonios: {
            descripcion: {
                es: "Nuestros Huéspedes",
                en: "Our guests"
            },
            danielZ: {
                es: "El hotel Marsella es sin duda alguna el mejor hotel en qué me he hospedado, la habitación muy acogedora,la limpieza de lo mejor, con detalles de decoración que logran la función de proporcionarte un ambiente tranquilo para descansar. El restaurant brinda alimentos de la más alta calidad y con sabores realmente increíbles. Un hotel sin duda alguna al que volveré en todas mis visitas.",
                en: "The Marsella hotel is undoubtedly the best hotel I have stayed in, the room is very cozy, the cleaning of the best, with decoration details that achieve the function of providing you with a quiet environment to rest. The restaurant offers food of the highest quality and with truly incredible flavors. A hotel without a doubt that I will return to on all my visits."
            },
            ketyMar: {
                es: "Excelente hotel, instalaciones con lo último en tecnología, moderno, cómodo, la atención por parte del personal fue excelente, mi estancia realmente fue satisfactoria, la comida muy rica, lo recomiendo al 100%",
                en: "Excellent hotel, facilities with the latest technology, modern, comfortable, the attention from the staff was excellent, my stay was really satisfactory, the food was very tasty, I recommend it 100%"
            },
            jazz: {
                es: "Gran lugar, excelente atención, muy padre diseño y magnifico restaurante",
                en: "Great place, excellent service, very cool design and magnificent restaurant"
            }
        },

        // Footer
        footer: {
            descripcion: {
                es: "Únete a nuestro equipo de trabajo",
                en: "Join our work team"
            },
            contactanos: {
                es: "Contáctanos",
                en: "Contact Us"
            },
            encuentranos: {
                es: "Encuéntranos",
                en: "Find Us"
            },
            reservaciones: {
                es: "Reservaciones",
                en: "Reservations"
            },
            galeria: {
                es: "Galería",
                en: "Gallery"
            },
            nosotros: {
                es: "Nosotros",
                en: "About"
            },
            quienesSomos: {
                es: "Quienes Somos",
                en: "About Us"
            },
            servicios: {
                es: "Servicios",
                en: "Services"
            },
            contacto: {
                es: "Contacto",
                en: "Contact"
            },
            avisoPrivacidad: {
                es: "Aviso de Privacidad",
                en: "Notice of Privacy"
            },
            recibeNoticias: {
                es: "Recibe noticias y promociones al inscribirte en nuestro boletin",
                en: "Receive news and promotions by signing up for our newsletter"
            },
            derechosReservados: {
                es: "Todos los derechos reservados.",
                en: "All rights reserved."
            }
        },

        // Boletin
        boletin: {
            es: "Ingresa tu correo electrónico",
            en: "Type your email"
        }
    },

    // Ayuda
    ayuda: {
        es: "¿NECESITAS AYUDA",
        en: "DO YOU NEED HELP?"
    },

    // Habitaciones
    habitaciones: {
        camaKing: {
            es: "Cama King Size",
            en: "King Size Bed"
        },
        vistaJardin: {
            es: "Vista al Jardín",
            en: "Garden view"
        },
        descripcion: {
            es: "Descripción",
            en: "Descrption"
        },
        nuestrasSuites: {
            es: "Nuestras Suites",
            en: "Our Suites"
        },
        luxuryDescMd:{
            es: "Con una superfície de 121 m2 la Luxury Suite cuenta con una cama King Size, sala, comedor y una oficina independiente.",
            en: "With an area of 121 m2, the Luxury Suite has a King Size bed, living room, dining room and a separate office."
        },
        masterDescMd:{
            es: "Con una superfície de 82 m2 la habitación Master Suite cuenta con una cama King Size, 1 baño, aire acondiciado y balcón.",
            en: "With an area of 82 m2, the Master Suite room has a King Size bed, 1 bathroom, air conditioning and a balcony."
        },
        juniorDesc: {
            es: "Con una superfície de 54 m2 la habitación Junior Suite cuenta con una cama King Size, 1 baño, aire acondiciado y balcón.",
            en: "With an area of 54 m2 the Junior Suite room has a King Size bed, 1 bathroom, air conditioning and balcony"
        },
        juniorServicios: {
            s1:{
                es: "1 Cama King Size",
                en: "1 King Size Bed",
            },
            s2:{
                es: "1 Baño",
                en: "1 Bathroom",
            },
            s3:{
                es: "Servicio de habitaciones",
                en: "Room service",
            },
            s4:{
                es: "Televisión inteligente",
                en: "Smart TV",
            },
            s5:{
                es: "WIFI",
                en: "Wi-Fi",
            },
            s6:{
                es: "Balcón",
                en: "Balcony",
            },
            s7:{
                es: "Seguridad las 24 horas",
                en: "24 hours Security",
            },
            s8:{
                es: "Aire acondicionado",
                en: "AC",
            },
            s9:{
                es: "Baño",
                en: "Restroom",
            },
            s10:{
                es: "Bata de Baño",
                en: "BathRobe",
            },
            s11:{
                es: "Acceso a Internet de banda ancha",
                en: "Broadband Internet access",
            },
            s12:{
                es: "Satélite/TV por cable",
                en: "Satellite / Cable TV",
            },
            s13:{
                es: "Artículos de aseo gratuitos",
                en: "Free toiletries",
            },
            s14:{
                es: "Bañera caliente",
                en: "Hot tub",
            },
            s15:{
                es: "Servicio de limpieza",
                en: "Housekeeping",
            },
            s16:{
                es: "Habitaciones interconectadas",
                en: "Interconnected rooms",
            },
            s17:{
                es: "Plancha y tabla de planchar",
                en: "Iron and ironing board",
            },
            s18:{
                es: "Luz ambiente",
                en: "Ambient light",
            },
            s19:{
                es: "Cuarto de baño privado",
                en: "Private bathroom",
            },
            s20:{
                es: "Patio Sala de Televisión",
                en: "Patio TV Room",
            },
            s21:{
                es: "Escaleras",
                en: "Ladders",
            },
            s22:{
                es: "Piscina",
                en: "Pool",
            },
            s23:{
                es: "Teléfono",
                en: "Phone",
            },
            s24:{
                es: "Cunas disponibles",
                en: "Cots available",
            },
            s25:{
                es: "Escritorio",
                en: "Desk",
            },
            s26:{
                es: "Secador de pelo",
                en: "Hair dryer",
            },
            s27:{
                es: "Cafetera expreso",
                en: "Espresso machine",
            },
            s28:{
                es: "Periódico gratuito",
                en: "Free newspaper",
            },
            s29:{
                es: "Ducha-separada",
                en: "Separate shower",
            },
            s30:{
                es: "TV pantalla plana",
                en: "Flat screen tv",
            },
            s31:{
                es: "Acceso a internet",
                en: "Internet Access",
            },
            s32:{
                es: "Terraza",
                en: "Terrace",
            },
            s33:{
                es: "Caja Fuerte",
                en: "Safe",
            }
        },
        juniorDoble:{
            desc: {
                es: "Con una superfície de 54 m2 la habitación Junior Suite Doble cuenta con dos camas Queen Size, 1 baño, aire acondiciado y balcón.",
                en: "With an area of 54 m2, the Junior Suite Double room has two Queen Size beds, 1 bathroom, air conditioning and a balcony."
            },
            camasQueen: {
                es: "2 Camas Queen Size",
                en: "2 Queen Size Beds"
            }
        }
    },
    
    // Página servicios
    serviciosPagina: {
        serviciosYAmenidadesTitle: {
            es: "Servicios y Amenidades | Marsella 45 Casa Abeyta",
            en: "Services and Amenities | Marsella 45 Casa Abeyta"
        },
        conoceNuestrasInstalaciones: {
            es: "CONOCE NUESTRAS INSTALACIONES Y SERVICIOS",
            en: "KNOW OUR FACILITIES AND SERVICES"
        },
        servciosEInstalaciones: {
            es: "Instalaciones & Servicios",
            en: "Facilities & Services"
        },
        parrafo1: {
            es: "Hay tanto que compartir de la experiencia Marsella 45. Nuestro objetivo es que cada una sea única, tan memorable que vaya directo a tus sueños y corazón.",
            en: "There is so much to share about the Marsella 45 experience. Our goal is for each one to be unique, so memorable that it goes straight to your dreams and heart."
        },
        parrafo2: {
            es: "La calidez y atención personalizada en nuestro servicio nos permite ir más allá de una simple bienvenida, acompañamos a nuestros huéspedes durante toda su estancia",
            en: "The warmth and personalized attention in our service allows us to go beyond a simple welcome, we accompany our guests throughout their stay"
        },
        parrafo3: {
            es: "Marsella 45 abraza cada uno de los sentidos con su aroma, su música ambiental, las sábanas de 800 hilos, su gastronomía y el refinado buen gusto en su interiorismo.",
            en: "Marsella 45 embraces each of the senses with its aroma, its ambient music, the 800-thread-count sheets, its gastronomy and the refined good taste in its interior design."
        },
        lavanderia: {
            es: "Lavandería / Tintorería",
            en: "Laundry / Dry Cleaning "
        },
        valetParking: {
            es: "Servicio de Valet Parking",
            en: "Valet Parking"
        },
        estacionamiento: {
            es: "Estanionamiento Propio Sin Costo",
            en: "Free-Charge Private Parking"
        },
        cajaSeguridad: {
            es: "Caja de Seguridad",
            en: "Safety Box"
        },
        servicioCuartos: {
            es: "Servicio a Cuartos",
            en: "Room Service"
        }
    },

    // Quiriego Página
    cocina: {
        cocinaRustica: {
            es: "Cocina Rústica",
            en: "Rustic Kitchen"
        },
        unaExperienciaInolvidable: {
            es: "UNA EXPERIENCIA CULINARIA INOLVIDABLE",
            en: "AN UNFORGETTABLE CULINARY EXPERIENCE"
        },
        cocinaDesc: {
            es: "Considerado localmente como la mejor cocina de la ciudad, Restaurante Quiriego ofrece un ambiente totalmente relajado, con servicio de desayuno, comida y cena, ya sea en espacios interiores o en terraza. Y para algo más intimo o formal, cuenta también con 2 salones privados y un semi-privado,con capacidades que van desde 8 hasta 12 personas.",
            en: "Locally considered the best cuisine in the city, Quiriego Restaurant offers a totally relaxed atmosphere, with breakfast, lunch and dinner service, either indoors or on the terrace. And for something more intimate or formal, it also has 2 private rooms and a semi-private one, with capacities ranging from 8 to 12 people."
        }
    }
}

const lang = () => {
    return texts
};

export default lang;