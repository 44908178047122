import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import Lang from "../../lang";

class LuxuryMed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
    };
  }

  componentDidMount() {
    let _lang = localStorage.getItem("marsellaLang")
    let _langu = localStorage.getItem("marsellaLangu")
    if (_lang && _langu) {
      this.setState({ language: _lang, changeLang: _langu})
    } else {
      localStorage.setItem("marsellaLang", Lang().default)
      localStorage.setItem("marsellaLangu", Lang().btnDefault)
      this.setState({ language: Lang().default, changeLang: Lang().btnDefault})
    }
  }
  
  render() {
    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true
    };

    return (
      <div className="col-lg-4 col-md-6">
        <div className="room_details">
          <div className="room_show">
            <Slider {...settings}>
              <div className="room_img">
                <img
                  src="assets/img/luxury/luxury-suite-hermosillo-medium.png"
                  alt=""
                />
              </div>

              <div className="room_img">
                <img
                  src="assets/img/luxury/luxury-suite-hermosillo-medium-2.png"
                  alt=""
                />
              </div>

              <div className="room_img">
                <img
                  src="assets/img/luxury/luxury-suite-hermosillo-medium-3.png"
                  alt=""
                />
              </div>
            </Slider>
          </div>
          <div className="room_info">
            <h3>
              <Link to="/luxury-suite">Luxury Suite</Link>
            </h3>
            <ul className="ppt-list">
              <li>
                <img src="assets/images/icy1.png" alt="" />2 {Lang().generico.adultos[this.state.language]} {Lang().generico.o[this.state.language]} 2 {Lang().generico.ninos[this.state.language]}
              </li>
              <li>
                <img src="assets/images/icy2.png" alt="" />
                121 m²
              </li>
            </ul>

            <p>
            {Lang().habitaciones.luxuryDescMd[this.state.language]}
            </p>
            <ul className="fct-list">
              <li>
                <span data-toggle="tooltip" data-placement="top" title="Wifi">
                  <img src="assets/images/ci1.png" alt="" />
                </span>
              </li>
              <li>
                <span data-toggle="tooltip" data-placement="top" title="Plane">
                  <img src="assets/images/ci2.png" alt="" />
                </span>
              </li>
              <li>
                <span data-toggle="tooltip" data-placement="top" title="Car">
                  <img src="assets/images/ci3.png" alt="" />
                </span>
              </li>
              <li>
                <span
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Breakfast"
                >
                  <img src="assets/images/ci4.png" alt="" />
                </span>
              </li>
            </ul>

            <Link to="/luxury-suite" title="" className="lnk-default">
            {Lang().generico.reservarAhora[this.state.language]}<i className="la la-arrow-right"></i>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
export default LuxuryMed;
