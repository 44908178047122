import React, { Component } from 'react';
import Slider from 'react-slick';
import Lang from "../../lang";

class AmenidadesA extends Component {

  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
    };
  }

  componentDidMount() {
    let _lang = localStorage.getItem("marsellaLang")
    let _langu = localStorage.getItem("marsellaLangu")
    if (_lang && _langu) {
      this.setState({ language: _lang, changeLang: _langu})
    } else {
      localStorage.setItem("marsellaLang", Lang().default)
      localStorage.setItem("marsellaLangu", Lang().btnDefault)
      this.setState({ language: Lang().default, changeLang: Lang().btnDefault})
    }
  }

  render() {
    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: true,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 572,
          settings: {
            slidesToShow: 1,
            arrows: false
          }
        }
      ]
    };
    return (
      <section className="c-block light-bg">
        <div className="container">
          <div className="sec-title">
            <h3>{Lang().landing.amenidades.nuestrasAmenidades[this.state.language]}</h3>
          </div>
          <div className="blog-items">
            <Slider {...settings}>
              <div className="amenidades-card">
                <div className="blog">
                  <div className="blog-img">
                    <img src="/assets/img/alberca-2.jpg" alt="" />
                  </div>
                  <div className="blog-info">
                    <h3 className="post-title">{Lang().landing.amenidades.alberca[this.state.language]}</h3>
                    <p>
                    {Lang().landing.amenidades.albercaDesc[this.state.language]}
                    </p>
                  </div>
                </div>
              </div>
              <div className="amenidades-card">
                <div className="blog">
                  <div className="blog-img">
                    <img src="/assets/img/restaurante-2.jpg" alt="" />
                  </div>
                  <div className="blog-info">
                    <h3 className="post-title">{Lang().landing.amenidades.restaurante[this.state.language]}</h3>
                    <p>
                      {Lang().landing.amenidades.restauranteDesc[this.state.language]}{' '}
                    </p>
                  </div>
                </div>
              </div>
              <div className="amenidades-card">
                <div className="blog">
                  <div className="blog-img">
                    <img src="/assets/img/cigar-room.jpg" alt="" />
                  </div>
                  <div className="blog-info">
                    <h3 className="post-title">Cigar Room</h3>
                    <p>
                    {Lang().landing.amenidades.cigarRoomDesc[this.state.language]}
                    </p>
                  </div>
                </div>
              </div>
              <div className="amenidades-card">
                <div className="blog">
                  <div className="blog-img">
                    <img src="/assets/img/gimnasio-2.jpg" alt="" />
                  </div>
                  <div className="blog-info">
                    <h3 className="post-title">{Lang().landing.amenidades.gimnasio[this.state.language]}</h3>
                    <p>
                    {Lang().landing.amenidades.gimnasioDesc[this.state.language]}
                    </p>
                  </div>
                </div>
              </div>

              <div className="amenidades-card">
                <div className="blog">
                  <div className="blog-img">
                    <img src="/assets/img/la-cava-2.jpg" alt="" />
                  </div>
                  <div className="blog-info">
                    <h3 className="post-title">{Lang().landing.amenidades.privado[this.state.language]}</h3>
                    <p>
                    {Lang().landing.amenidades.privadoDesc[this.state.language]}
                    </p>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </section>
    );
  }
}

export default AmenidadesA;
