import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Video from '../common/Video';
import Testimonios from '../common/Testimonios';
import { Helmet } from 'react-helmet';

class QuienesSomos extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <meta
            name="description"
            content="Marsella 45 Casa Abeyta. Hotel Boutique de lujo en el corazón de Hermosillo. Vive una experiencia única. Hospédate en el hotel más lujoso de la ciudad"
          />
          <meta
            property="og:title"
            content="Hotel Marsella 45 Casa Abeyta - Nosotros | Hermosillo"
          />
          <meta property="og:type" content="article" />
          <meta
            property="og:url"
            content="https://cocky-bhaskara-cb37ab.netlify.com/quienes-somos"
          />
          <meta
            property="og:image"
            content="https://cocky-bhaskara-cb37ab.netlify.com/assets/img/logo-marsella45-hermosillo.png"
          />
          <title>Hotel Marsella 45 Casa Abeyta - Nosotros | Hermosillo</title>
        </Helmet>
        <section className="pager-sec quienes-somos-sec">
          <div className="container">
            <div className="pager-sec-details">
              <h3>Quienes Somos</h3>
              <ul>
                <li>
                  <Link to="/home">Inicio</Link>
                </li>
                <li>
                  <span>Quienes Somos</span>
                </li>
              </ul>
            </div>
          </div>
        </section>

        <section className="c-block white-bg">
          <div className="container">
            <div className="about-us-sec about-pg">
              <div className="row">
                <div className="col-lg-5">
                  <div className="about_we">
                    <h2 id="marsella-45">Marsella 45</h2>
                    <h2>
                      Una Experiencia <span>que debes</span> vivir.
                    </h2>
                  </div>
                </div>
                <div className="col-lg-7 p-0">
                  <ul className="abt_img">
                    <li>
                      <img src="assets/img/quienes-somos-int-1.png" alt="" />
                    </li>
                    <li>
                      <img src="assets/img/quienes-somos-int-2.png" alt="" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="c-block resot-sec">
          <div className="container">
            <div className="sec-title">
              <h3>¿Por qué Nosotros?</h3>
            </div>

            <div className="resot_sec">
              <div className="row">
                <div className="col-lg-3 col-md-3 col-sm-6">
                  <div className="resot-dv">
                    <div className="resot-icon">
                      <i className="la la-balance-scale"></i>
                    </div>
                    <h3>Armonía</h3>
                    <p>
                      Aroma, Música, Gastronomía y gustos refinados en su
                      interior que invitan a despejar la mente
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6">
                  <div className="resot-dv">
                    <div className="resot-icon">
                      <i className="la la-smile-o"></i>
                    </div>
                    <h3>Hospitalidad</h3>
                    <p>Calidez y atención personalizada en nuestro servicio</p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6">
                  <div className="resot-dv">
                    <div className="resot-icon">
                      <i className="la la-diamond"></i>
                    </div>
                    <h3>Suites de Lujo</h3>
                    <p>
                      Lujo y refinamiento en nuestras suites que innova el
                      concepto de hospedaje de gran clase
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6">
                  <div className="resot-dv">
                    <div className="resot-icon">
                      <i className="la la-globe"></i>
                    </div>
                    <h3>Eco-friendly</h3>
                    <p>
                      Conscientes de nuestro entorno y en apoyo a un mundo sin
                      plásticos
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Video />
        <Testimonios />
      </div>
    );
  }
}
export default QuienesSomos;
