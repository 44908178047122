import React, { Component } from "react";
import { Link } from "react-router-dom";
import MenuBar from "./mobile-menu/MenuBar";
import MobileMenu from "./mobile-menu/MobileMenu";
import MenuItem from "./mobile-menu/MenuItem";
import Lang from "../../lang";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false
    };
  }

  componentDidMount() {
    let _lang = localStorage.getItem("marsellaLang")
    let _langu = localStorage.getItem("marsellaLangu")
    if (_lang && _langu) {
      this.setState({ language: _lang, changeLang: _langu})
    } else {
      localStorage.setItem("marsellaLang", Lang().default)
      localStorage.setItem("marsellaLangu", Lang().btnDefault)
      this.setState({ language: Lang().default, changeLang: Lang().btnDefault})
    }

    if (_lang === 'es'){
      this.setState({ linke: "contacto.html"})
    } else {
      this.setState({ linke: "contact.html"})
    }
  }

  handleLangClick(la) {
    if(la === 'es'){
      this.setState({ language: 'en', changeLang: 'ESPAÑOL'})
      localStorage.setItem("marsellaLang", "en")
      localStorage.setItem("marsellaLangu", "ESPAÑOL")
    }
    else {
      this.setState({ language: 'es', changeLang: 'ENGLISH'})
      localStorage.setItem("marsellaLang", "es")
      localStorage.setItem("marsellaLangu", "ENGLISH")
    }

    window.location.reload();
  }

  handleMenuClick() {
    this.setState({ menuOpen: !this.state.menuOpen });
  }

  handleLinkClick() {
    this.setState({ menuOpen: false });
  }

  render() {
    return (
      <div>
        <header className="pb hp1 shugert-header-mobile">
          <div className="top-header">
            <div className="container-fluid">
              <ul className="cont-list">
                <li>
                  <a
                    onClick="return gtag_report_conversion('tel:662-520-0200');"
                    href="tel:662-520-0200"
                  >
                    (662) 520-0200
                  </a>
                </li>
                <li>
                  <a href="mailto:reservaciones@marsella45.mx" title="">
                    reservaciones@marsella45.mx
                  </a>
                </li>
              </ul>

              <ul className="social-links">
                <li>
                  <a href="https://www.facebook.com/Marsella45mx/">
                    <i className="fab fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/marsella45mx/">
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a href="mailto:reservaciones@marsella45.mx" title="">
                    <i className="fa fa-envelope"></i>
                  </a>
                </li>
              </ul>
              <div className="clearfix"></div>
            </div>
          </div>

          <div className="bottom-header">
            <div className="container-fluid">
              <div className="ft-logo">
                <Link to="/home">
                  <img
                    src="/assets/img/marsella-logo-navbar.png"
                    alt="marsella 45 logo"
                  />
                </Link>
              </div>

              <ul className="src-book">
                <li>
                  <Link to="/suites">
                    <img src="/assets/images/icon5.png" alt="" />
                  </Link>
                  <Link to="/suites" className="bk-now">
                  {Lang().menu.reservaciones[this.state.language]}
                  </Link>
                </li>
              </ul>

              <nav>
                <ul>
                  <li>
                    <Link to="/home">{Lang().menu.inicio[this.state.language]}</Link>
                  </li>
                  <li className="custom-suites-margin">
                    <a href="#" title="">
                      Suites
                    </a>
                    <ul>
                      <li>
                        <Link to="/junior-suite">Junior Suite</Link>
                      </li>
                      <li>
                        <Link to="/junior-suite-doble">Junior Suite Doble</Link>
                      </li>
                      <li>
                        <Link to="/master-suite">Master Suite</Link>
                      </li>
                      <li>
                        <Link to="/luxury-suite">Luxury Suite</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/servicios">{Lang().menu.servicios[this.state.language]}</Link>
                  </li>
                  <li>
                    <Link to="restaurante-quiriego">Quiriego</Link>
                  </li>
                  <li>
                    <a href={this.state.linke} title="">
                    {Lang().menu.contacto[this.state.language]}
                    </a>
                  </li>
                </ul>
              </nav>
              <MenuBar
                open={this.state.menuOpen}
                onClick={() => this.handleMenuClick()}
                color="white"
              />
              <div className="clearfix"></div>
            </div>
          </div>
        </header>
        <MobileMenu open={this.state.menuOpen}>
          <MenuItem
            key={0}
            delay={`${0 * 0.1}s`}
            onClick={() => {
              this.handleLinkClick();
            }}
          >
            <Link to="/home">{Lang().menu.inicio[this.state.language]}</Link>
          </MenuItem>
          <MenuItem
            key={1}
            delay={`${1 * 0.1}s`}
            onClick={() => {
              this.handleLinkClick();
            }}
          >
            <Link to="/servicios">{Lang().menu.servicios[this.state.language]}</Link>
          </MenuItem>
          <MenuItem
            key={2}
            delay={`${2 * 0.1}s`}
            onClick={() => {
              this.handleLinkClick();
            }}
          >
            <Link to="/suites">Suites</Link>
          </MenuItem>
          <MenuItem
            key={3}
            delay={`${3 * 0.1}s`}
            onClick={() => {
              this.handleLinkClick();
            }}
          >
            <Link to="/junior-suite">Junior Suite</Link>
          </MenuItem>
          <MenuItem
            key={4}
            delay={`${4 * 0.1}s`}
            onClick={() => {
              this.handleLinkClick();
            }}
          >
            <Link to="/junior-suite-doble">Junior Suite Doble</Link>
          </MenuItem>
          <MenuItem
            key={5}
            delay={`${5 * 0.1}s`}
            onClick={() => {
              this.handleLinkClick();
            }}
          >
            <Link to="/master-suite">Master Suite</Link>
          </MenuItem>
          <MenuItem
            key={6}
            delay={`${6 * 0.1}s`}
            onClick={() => {
              this.handleLinkClick();
            }}
          >
            <Link to="/luxury-suite">Luxury Suite</Link>
          </MenuItem>
          <MenuItem
            key={7}
            delay={`${7 * 0.1}s`}
            onClick={() => {
              this.handleLinkClick();
            }}
          >
            <Link to="/restaurante-quiriego">Quiriego</Link>
          </MenuItem>
          <MenuItem
            key={8}
            delay={`${8 * 0.1}s`}
            onClick={() => {
              this.handleLinkClick();
            }}
          >
            <Link to="/galeria">{Lang().menu.galeria[this.state.language]}</Link>
          </MenuItem>
          <MenuItem
            key={9}
            delay={`${9 * 0.1}s`}
            onClick={() => {
              this.handleLinkClick();
            }}
          >
            <a href={this.state.linke}>{Lang().menu.contacto[this.state.language]}</a>
          </MenuItem>
        </MobileMenu>

        <header className="pb hp1 shugert-header-desktop">
          <div className="top-header">
            <div className="container-fluid">
              <ul className="cont-list">
                <li>
                  <a
                    onClick="return gtag_report_conversion('tel:662-520-0200');"
                    href="tel:662-520-0200"
                  >
                    (662) 520-0200
                  </a>
                </li>
                <li>
                  <a href="mailto:reservaciones@marsella45.mx" title="">
                    reservaciones@marsella45.mx
                  </a>
                </li>
              </ul>

              <ul className="social-links">
                <li>
                  <a href="https://www.facebook.com/Marsella45mx/">
                    <i className="fab fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/marsella45mx/">
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a href="mailto:reservaciones@marsella45.mx" title="">
                    <i className="fa fa-envelope"></i>
                  </a>
                </li>
              </ul>
              <div className="clearfix"></div>
            </div>
          </div>

          <div className="bottom-header">
            <div className="container-fluid">
              <div className="ft-logo">
                <Link to="/home">
                  <img
                    src="/assets/img/marsella-logo-navbar.png"
                    alt="marsella 45 logo"
                  />
                </Link>
              </div>

              <ul className="src-book">
                <li>
                  <Link to="/suites">
                    <img src="/assets/images/icon5.png" alt="" />
                  </Link>
                  <Link to="/suites" className="bk-now">
                    {Lang().menu.reservaciones[this.state.language]}
                  </Link>
                </li>
                <li>
                  {/* <Link to="/suites">
                    <img src="/assets/images/icon5.png" alt="" />
                  </Link> */}
                  <div className="bk-now" onClick={() => this.handleLangClick(this.state.language)} style={{cursor: "pointer"}}>
                    {this.state.changeLang}
                  </div>
                </li>
              </ul>

              <nav>
                <ul>
                  <li>
                    <Link to="/home">{Lang().menu.inicio[this.state.language]}</Link>
                  </li>
                  <li className="custom-suites-margin">
                    <Link to="/suites">Suites</Link>
                    <ul>
                      <li>
                        <Link to="/junior-suite">Junior Suite</Link>
                      </li>
                      <li>
                        <Link to="/junior-suite-doble">Junior Suite Doble</Link>
                      </li>
                      <li>
                        <Link to="/master-suite">Master Suite</Link>
                      </li>
                      <li>
                        <Link to="/luxury-suite">Luxury Suite</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/servicios">{Lang().menu.servicios[this.state.language]}</Link>
                  </li>
                  <li>
                    <Link to="restaurante-quiriego">Quiriego</Link>
                  </li>
                  <li>
                    <a href={this.state.linke} title="">
                    {Lang().menu.contacto[this.state.language]}
                    </a>
                  </li>
                </ul>
              </nav>
              <div className="menu-bar">
                <a href="#" title="">
                  <span className="bar1"></span>
                  <span className="bar2"></span>
                  <span className="bar3"></span>
                </a>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </header>
      </div>
    );
  }
}

export default Navbar;
