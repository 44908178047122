import React, { Component } from "react";
import Lang from "../../lang";

export default class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false
    };
  }

  componentDidMount() {
    let _lang = localStorage.getItem("marsellaLang")
    let _langu = localStorage.getItem("marsellaLangu")
    if (_lang && _langu) {
      this.setState({ language: _lang, changeLang: _langu})
    } else {
      localStorage.setItem("marsellaLang", Lang().default)
      localStorage.setItem("marsellaLangu", Lang().btnDefault)
      this.setState({ language: Lang().default, changeLang: Lang().btnDefault})
    }
  }

  render() {
    return (
      <section className="relax-sec">
        <div className="sec-title titulo-armonia">
          <h3>{Lang().landing.video.descripcion[this.state.language]}</h3>
        </div>
        <div className="js-videoWrapper">
          <iframe
            className="videoIframe js-videoIframe"
            src=""
            frameborder="0"
            allowTransparency="true"
            allowfullscreen
            data-src="https://www.youtube.com/embed/QlhN3RQTAbA?autoplay=1&modestbranding=1&rel=0&hl=sv&showinfo=0&controls=0?vq=hd720"
          ></iframe>

          <button
            className="videoPoster js-videoPoster"
            style={{
              backgroundImage: 'url(/assets/img/video-image.jpg)',
              marginTop: '90px'
            }}
          >
            Play video
          </button>
        </div>
      </section>
    );
  }
}
