import React, { Component } from "react";
import Lang from "../../lang";

export default class NecesitasAyuda extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    let _lang = localStorage.getItem("marsellaLang")
    let _langu = localStorage.getItem("marsellaLangu")
    if (_lang && _langu) {
      this.setState({ language: _lang, changeLang: _langu})
    } else {
      localStorage.setItem("marsellaLang", Lang().default)
      localStorage.setItem("marsellaLangu", Lang().btnDefault)
      this.setState({ language: Lang().default, changeLang: Lang().btnDefault})
    }
  }

  render() {
    return (
      <div class="wid-info">
        <h3>{Lang().ayuda[this.state.language]}</h3>
        <ul>
          <li>
            <a
              onClick="return gtag_report_conversion('tel:662-520-0200');"
              href="tel:662-520-0200"
            >
              (662) 520-0200
            </a>
          </li>
          <li>
            <a href="mailto:reservaciones@marsella45.mx" title="">
              reservaciones@marsella45.mx
            </a>
          </li>
        </ul>
      </div>
    );
  }
}
