import React, { Component } from "react";
import Lightbox from "react-image-lightbox";

class GaleriaMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photoIndex: 0,
      isOpen: false
    };
  }
  render() {
    const images = [
      "assets/img/master/master-suite-hermosillo-2.jpg",
      "assets/img/master/master-suite-hermosillo-4.jpg",
      "assets/img/master/master-suite-hermosillo-6.jpg",
      "assets/img/master/master-suite-hermosillo-8.jpg",
      "assets/img/master/master-suite-hermosillo-10.jpg",
      "assets/img/master/master-suite-hermosillo-12.jpg",
      "assets/img/master/master-suite-hermosillo-14.jpg",
      "assets/img/master/master-suite-hermosillo-16.jpg",
      "assets/img/master/master-suite-hermosillo-18.jpg",
      "assets/img/master/master-suite-hermosillo-20.jpg",
      "assets/img/master/master-suite-hermosillo-22.jpg",
      "assets/img/master/master-suite-hermosillo-24.jpg",
      "assets/img/master/master-suite-hermosillo-26.jpg",
      "assets/img/master/master-suite-hermosillo-28.jpg",
      "assets/img/master/master-suite-hermosillo-30.jpg",
      "assets/img/master/master-suite-hermosillo-32.jpg",
      "assets/img/master/master-suite-hermosillo-34.jpg"
    ];
    let imageItems = images.map((image, i) => {
      return (
        <div className="col-lg-4 col-md-4 col-sm-6">
          <div className="gd-item">
            <img src={image} alt="" />
            <a
              onClick={() => this.setState({ isOpen: true, photoIndex: i })}
              title=""
              className="html5lightbox zoom-img-3"
            >
              <img src="/assets/images/zoom.png" alt="" />
            </a>
          </div>
        </div>
      );
    });
    const { photoIndex, isOpen } = this.state;

    return (
      <div
        className="tab-pane fade"
        id="tab2"
        role="tabpanel"
        aria-labelledby="tab_2"
      >
        <div className="gallery-grid">
          <div className="row">{imageItems}</div>
        </div>
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length
              })
            }
          />
        )}
      </div>
    );
  }
}
export default GaleriaMaster;
