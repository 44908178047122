import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import GaleriaLuxury from '../gallery/GaleriaLuxury';
import GaleriaMaster from '../gallery/GaleriaMaster';
import GaleriaJunior from '../gallery/GaleriaJunior';
import GaleriaJuniorDoble from '../gallery/GaleriaJuniorDoble';
import GaleriaMarsella from '../gallery/GaleriaMarsella';

class Galeria extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <meta
            name="description"
            content="Marsella 45 Casa Abeyta. Hotel Boutique de lujo en el corazón de Hermosillo. Vive una experiencia única. Hospédate en el hotel más lujoso de la ciudad"
          />
          <meta
            property="og:title"
            content="Hotel Marsella 45 Casa Abeyta - Galería de fotos | Hermosillo"
          />
          <meta property="og:type" content="article" />
          <meta
            property="og:url"
            content="https://cocky-bhaskara-cb37ab.netlify.com/galeria"
          />
          <meta
            property="og:image"
            content="https://cocky-bhaskara-cb37ab.netlify.com/assets/img/logo-marsella45-hermosillo.png"
          />
          <title>
            Hotel Marsella 45 Casa Abeyta - Galería de fotos | Hermosillo
          </title>
        </Helmet>

        <section className="pager-sec galeria-sec">
          <div className="container">
            <div className="pager-sec-details">
              <h3>Galería</h3>
              <ul>
                <li>
                  <Link to="/home">Inicio</Link>
                </li>
                <li>
                  <span>Galería</span>
                </li>
              </ul>
            </div>
          </div>
        </section>

        <section className="c-block pb-0">
          <div className="container">
            <div className="sec-title"></div>

            <div className="gallery-grid">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="tab_1"
                    data-toggle="tab"
                    href="#tab1"
                    role="tab"
                    aria-controls="tab_1"
                    aria-selected="true"
                  >
                    Luxury Suite
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="tab_2"
                    data-toggle="tab"
                    href="#tab2"
                    role="tab"
                    aria-controls="tab_2"
                    aria-selected="false"
                  >
                    Master Suite
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="tab_3"
                    data-toggle="tab"
                    href="#tab3"
                    role="tab"
                    aria-controls="tab_3"
                    aria-selected="false"
                  >
                    Junior Suite
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="tab_4"
                    data-toggle="tab"
                    href="#tab4"
                    role="tab"
                    aria-controls="tab_4"
                    aria-selected="false"
                  >
                    Junior Suite Doble
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="tab_5"
                    data-toggle="tab"
                    href="#tab5"
                    role="tab"
                    aria-controls="tab_5"
                    aria-selected="false"
                  >
                    Marsella 45
                  </a>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <GaleriaLuxury />
                <GaleriaMaster />
                <GaleriaJunior />
                <GaleriaJuniorDoble />
                <GaleriaMarsella />
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Galeria;
