import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Lang from "../../lang";

class JuniorDobleSm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
    };
  }

  componentDidMount() {
    let _lang = localStorage.getItem("marsellaLang")
    let _langu = localStorage.getItem("marsellaLangu")
    if (_lang && _langu) {
      this.setState({ language: _lang, changeLang: _langu})
    } else {
      localStorage.setItem("marsellaLang", Lang().default)
      localStorage.setItem("marsellaLangu", Lang().btnDefault)
      this.setState({ language: Lang().default, changeLang: Lang().btnDefault})
    }
  }

  render() {
    return (
      <li>
        <div className="wd-post">
          <div className="wd-post-img">
            <img
              src="assets/img/jr-suite-doble/jr-suite-doble-hermosillo-mini.jpg"
              alt=""
            />
          </div>
          <div className="wd-post-info">
            <h3>
              <Link to="/junior-suite-doble">Junior Suite Doble</Link>
            </h3>
            <Link to="/junior-suite-doble">
              <span>{Lang().generico.reservarAhora[this.state.language]}</span><i className="la la-arrow-right"></i>
            </Link>
          </div>
        </div>
      </li>
    );
  }
}
export default JuniorDobleSm;
