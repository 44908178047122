import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import LuxuryMed from '../suites/LuxuryMed';
import JuniorDobleMed from '../suites/JuniorDobleMed';
import JuniorMed from '../suites/JuniorMed';
import MasterMed from '../suites/MasterMed';
import Slider from 'react-slick';

class Suites extends Component {
  render() {
    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true
    };
    return (
      <div>
        <Helmet>
          <meta
            name="description"
            content="Marsella 45 se localiza en la zona más vibrante de Hermosillo con 20 espaciosas Suites que lo han convertido en un referente de lujo y refinamiento. Reserva Ahora."
          />
          <meta
            property="og:title"
            content="Suites - Marsella 45 | Hotel Boutique de Lujo - Hermosillo Sonora"
          />
          <meta property="og:type" content="article" />
          <meta
            property="og:url"
            content="https://cocky-bhaskara-cb37ab.netlify.com/suites"
          />
          <meta
            property="og:image"
            content="https://cocky-bhaskara-cb37ab.netlify.com/assets/img/logo-marsella45-hermosillo.png"
          />
          <title>
            Suites - Marsella 45 | Hotel Boutique de Lujo - Hermosillo Sonora
          </title>
        </Helmet>
        <section className="pager-sec suites-sec">
          <div className="container">
            <div className="pager-sec-details">
              <h3>Nuestras Suites</h3>
              <ul>
                <li>
                  <Link to="/home" title="">
                    Inicio
                  </Link>
                </li>
                <li>
                  <span>Nuestras Suites</span>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section className="c-block pb-0">
          <div className="container">
            <div className="sec-title">
              <h3>CONOCE NUESTRAS SUITES</h3>
            </div>
            <div className="rest_head">
              <div className="row">
                <div className="col-lg-12">
                  <p>
                    Marsella 45 emerge en la zona más vibrante de la ciudad con
                    20 espaciosas Suites que lo han convertido en un referente
                    de lujo y refinamiento de Hermosillo. Cada una de sus
                    Suites, indistintas en decoración contemporánea entre sí,
                    han llegado a innovar el concepto de hospedaje de gran
                    clase. Cada Suite se compone de sala, habitación y un
                    espacioso baño.
                  </p>
                </div>
              </div>
            </div>
            <div className="page-content">
              <div className="main-dv">
                <div className="main-dv-head">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        id="home-tab"
                        data-toggle="tab"
                        href="#home"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                      >
                        <img src="assets/images/bar-icon1.png" alt="" />
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="profile-tab"
                        data-toggle="tab"
                        href="#profile"
                        role="tab"
                        aria-controls="profile"
                        aria-selected="false"
                      >
                        <img src="assets/images/bar-icon2.png" alt="" />
                      </a>
                    </li>
                  </ul>
                  <div className="clearfix"></div>
                </div>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <div
                      style={{ padding: '20px' }}
                      className="tb-detaiils v13"
                    >
                      <div className="row">
                        <LuxuryMed />
                        <MasterMed />
                        <JuniorDobleMed />
                        <JuniorMed />
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="profile"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                  >
                    <div className="tb-detaiils">
                      <div className="tb-row">
                        <div className="room_details">
                          <div className="room-caroz">
                            <Slider {...settings}>
                              <div className="room_img">
                                <img
                                  src="assets/img/luxury/luxury-suite-hermosillo-medium.png"
                                  alt=""
                                />
                              </div>

                              <div className="room_img">
                                <img
                                  src="assets/img/luxury/luxury-suite-hermosillo-medium-2.png"
                                  alt=""
                                />
                              </div>

                              <div className="room_img">
                                <img
                                  src="assets/img/luxury/luxury-suite-hermosillo-medium-3.png"
                                  alt=""
                                />
                              </div>
                            </Slider>
                          </div>
                          <div className="room_info">
                            <h3>
                              <Link to="/luxury-suite">Luxury Suite</Link>
                            </h3>
                            <ul className="ppt-list">
                              <li>
                                <img src="assets/images/icy1.png" alt="" />2
                                adultos o 2 niños
                              </li>
                              <li>
                                <img src="assets/images/icy2.png" alt="" />
                                121 m²
                              </li>
                            </ul>
                            <p>
                              Con una superfície de 121 m<sup>2</sup> la Luxury
                              Suite cuenta con una cama King Size, sala, comedor
                              y una oficina independiente
                            </p>
                            <div className="fct-dv">
                              <ul className="fct-list">
                                <li>
                                  <span
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Wifi"
                                  >
                                    <img src="assets/images/ci1.png" alt="" />
                                  </span>
                                </li>
                                <li>
                                  <span
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Plane"
                                  >
                                    <img src="assets/images/ci2.png" alt="" />
                                  </span>
                                </li>
                                <li>
                                  <span
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Car"
                                  >
                                    <img src="assets/images/ci3.png" alt="" />
                                  </span>
                                </li>
                                <li>
                                  <span
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Breakfast"
                                  >
                                    <img src="assets/images/ci4.png" alt="" />
                                  </span>
                                </li>
                              </ul>
                              <Link to="/luxury-suite" className="lnk-default">
                                Reservar Ahora{' '}
                                <i className="la la-arrow-right"></i>
                              </Link>
                              <div className="clearfix"></div>
                            </div>
                          </div>
                          <div className="clearfix"></div>
                        </div>
                      </div>
                      <div className="tb-row">
                        <div className="room_details">
                          <div className="room-caroz">
                            <Slider {...settings}>
                              <div className="room_img">
                                <img
                                  src="assets/img/master/master-suite-hermosillo-medium.jpg"
                                  alt=""
                                />
                              </div>

                              <div className="room_img">
                                <img
                                  src="assets/img/master/master-suite-hermosillo-medium-2.jpg"
                                  alt=""
                                />
                              </div>

                              <div className="room_img">
                                <img
                                  src="assets/img/master/master-suite-hermosillo-medium-3.jpg"
                                  alt=""
                                />
                              </div>
                            </Slider>
                          </div>
                          <div className="room_info">
                            <h3>
                              <Link to="/master-suite">Master Suite</Link>
                            </h3>
                            <ul className="ppt-list">
                              <li>
                                <img src="assets/images/icy1.png" alt="" />2
                                adultos o 2 niños
                              </li>
                              <li>
                                <img src="assets/images/icy2.png" alt="" />
                                82 m²
                              </li>
                            </ul>
                            <p>
                              Con una superfície de 82 m <sup>2</sup> la
                              habitación Master Suite cuenta con una cama King
                              Size, 1 baño, aire acondiciado y balcón.
                            </p>
                            <div className="fct-dv">
                              <ul className="fct-list">
                                <li>
                                  <span
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Wifi"
                                  >
                                    <img src="assets/images/ci1.png" alt="" />
                                  </span>
                                </li>
                                <li>
                                  <span
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Plane"
                                  >
                                    <img src="assets/images/ci2.png" alt="" />
                                  </span>
                                </li>
                                <li>
                                  <span
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Car"
                                  >
                                    <img src="assets/images/ci3.png" alt="" />
                                  </span>
                                </li>
                                <li>
                                  <span
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Breakfast"
                                  >
                                    <img src="assets/images/ci4.png" alt="" />
                                  </span>
                                </li>
                              </ul>
                              <Link to="/master-suite" className="lnk-default">
                                Reservar Ahora{' '}
                                <i className="la la-arrow-right"></i>
                              </Link>
                              <div className="clearfix"></div>
                            </div>
                          </div>
                          <div className="clearfix"></div>
                        </div>
                      </div>

                      <div className="tb-row">
                        <div className="room_details">
                          <div className="room-caroz">
                            <Slider {...settings}>
                              <div className="room_img">
                                <img
                                  src="assets/img/jr-suite-doble/jr-suite-doble-hermosillo-medium.jpg"
                                  alt=""
                                />
                              </div>

                              <div className="room_img">
                                <img
                                  src="assets/img/jr-suite-doble/jr-suite-doble-hermosillo-medium-2.jpg"
                                  alt=""
                                />
                              </div>

                              <div className="room_img">
                                <img
                                  src="assets/img/jr-suite-doble/jr-suite-doble-hermosillo-medium-3.jpg"
                                  alt=""
                                />
                              </div>
                            </Slider>
                          </div>
                          <div className="room_info">
                            <h3>
                              <Link to="junior-suite-doble">
                                Junior Suite Doble
                              </Link>
                            </h3>
                            <ul className="ppt-list">
                              <li>
                                <img src="assets/images/icy1.png" alt="" />2
                                adultos o 4 niños
                              </li>
                              <li>
                                <img src="assets/images/icy2.png" alt="" />
                                54 Ft²
                              </li>
                            </ul>
                            <p>
                              Con una superfície de 54 m <sup>2</sup> la
                              habitación Junior Suite Doble cuenta con dos camas
                              Queen Size, 1 baño, aire acondiciado y balcón.
                            </p>
                            <div className="fct-dv">
                              <ul className="fct-list">
                                <li>
                                  <span
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Wifi"
                                  >
                                    <img src="assets/images/ci1.png" alt="" />
                                  </span>
                                </li>
                                <li>
                                  <span
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Plane"
                                  >
                                    <img src="assets/images/ci2.png" alt="" />
                                  </span>
                                </li>
                                <li>
                                  <span
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Car"
                                  >
                                    <img src="assets/images/ci3.png" alt="" />
                                  </span>
                                </li>
                                <li>
                                  <span
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Breakfast"
                                  >
                                    <img src="assets/images/ci4.png" alt="" />
                                  </span>
                                </li>
                              </ul>
                              <Link
                                to="/junior-suite-doble"
                                className="lnk-default"
                              >
                                Reservar Ahora{' '}
                                <i className="la la-arrow-right"></i>
                              </Link>
                              <div className="clearfix"></div>
                            </div>
                          </div>
                          <div className="clearfix"></div>
                        </div>
                      </div>

                      <div className="tb-row">
                        <div className="room_details">
                          <div className="room-caroz">
                            <Slider {...settings}>
                              <div className="room_img">
                                <img
                                  src="assets/img/jr-suite/jr-suite-hermosillo-medium.jpg"
                                  alt=""
                                />
                              </div>

                              <div className="room_img">
                                <img
                                  src="assets/img/jr-suite/jr-suite-hermosillo-medium-2.jpg"
                                  alt=""
                                />
                              </div>

                              <div className="room_img">
                                <img
                                  src="assets/img/jr-suite/jr-suite-hermosillo-medium-3.jpg"
                                  alt=""
                                />
                              </div>
                            </Slider>
                          </div>
                          <div className="room_info">
                            <h3>
                              <Link to="/junior-suite">Junior Suite</Link>
                            </h3>
                            <ul className="ppt-list">
                              <li>
                                <img src="assets/images/icy1.png" alt="" />2
                                adultos o 2 niños
                              </li>
                              <li>
                                <img src="assets/images/icy2.png" alt="" />
                                54 m²
                              </li>
                            </ul>
                            <p>
                              Con una superfície de 54 m 2 la habitación Junior
                              Suite cuenta con una cama King Size, 1 baño, aire
                              acondiciado y balcón.
                            </p>
                            <div className="fct-dv">
                              <ul className="fct-list">
                                <li>
                                  <span
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Wifi"
                                  >
                                    <img src="assets/images/ci1.png" alt="" />
                                  </span>
                                </li>
                                <li>
                                  <span
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Plane"
                                  >
                                    <img src="assets/images/ci2.png" alt="" />
                                  </span>
                                </li>
                                <li>
                                  <span
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Car"
                                  >
                                    <img src="assets/images/ci3.png" alt="" />
                                  </span>
                                </li>
                                <li>
                                  <span
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Breakfast"
                                  >
                                    <img src="assets/images/ci4.png" alt="" />
                                  </span>
                                </li>
                              </ul>
                              <Link to="/junior-suite" className="lnk-default">
                                Reservar Ahora{' '}
                                <i className="la la-arrow-right"></i>
                              </Link>
                              <div className="clearfix"></div>
                            </div>
                          </div>
                          <div className="clearfix"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Suites;
