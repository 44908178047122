import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import Lang from "../../lang";

class CocinaRustica extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false
    };
  }

  componentDidMount() {
    let _lang = localStorage.getItem("marsellaLang")
    let _langu = localStorage.getItem("marsellaLangu")
    if (_lang && _langu) {
      this.setState({ language: _lang, changeLang: _langu})
    } else {
      localStorage.setItem("marsellaLang", Lang().default)
      localStorage.setItem("marsellaLangu", Lang().btnDefault)
      this.setState({ language: Lang().default, changeLang: Lang().btnDefault})
    }
  }

  render() {
    const images = [
      "assets/img/cocina/Restaurante_Quiriego_1.jpg",
      "assets/img/cocina/Restaurante_Quiriego_2.jpg",
      "assets/img/cocina/Restaurante_Quiriego_3.jpg",
      "assets/img/cocina/Restaurante_Quiriego_4.jpg",
      "assets/img/cocina/Restaurante_Quiriego_5.jpg",
      "assets/img/cocina/Restaurante_Quiriego_6.jpg",
      "assets/img/cocina/Restaurante_Quiriego_7.jpg",
      "assets/img/cocina/Restaurante_Quiriego_8.jpg",
      "assets/img/cocina/Restaurante_Quiriego_9.jpg",
      "assets/img/cocina/Restaurante_Quiriego_10.jpg",
      "assets/img/cocina/Restaurante_Quiriego_11.jpg",
      "assets/img/cocina/Restaurante_Quiriego_12.jpg",
      "assets/img/cocina/Restaurante_Quiriego_13.jpg",
      "assets/img/cocina/Restaurante_Quiriego_14.jpg",
      "assets/img/cocina/Restaurante_Quiriego_15.jpg",
      "assets/img/cocina/Restaurante_Quiriego_16.jpg",
      "assets/img/cocina/Restaurante_Quiriego_17.jpg",
      "assets/img/cocina/Restaurante_Quiriego_18.jpg",
      "assets/img/cocina/Restaurante_Quiriego_19.jpg",
      "assets/img/cocina/Restaurante_Quiriego_20.jpg",
      "assets/img/cocina/Restaurante_Quiriego_21.jpg",
      "assets/img/cocina/Restaurante_Quiriego_22.jpg",
      "assets/img/cocina/Restaurante_Quiriego_23.jpg",
      "assets/img/cocina/Restaurante_Quiriego_24.jpg",
      "assets/img/cocina/Restaurante_Quiriego_25.jpg",
      "assets/img/cocina/Restaurante_Quiriego_26.jpg",
      "assets/img/cocina/Restaurante_Quiriego_27.jpg",
      "assets/img/cocina/Restaurante_Quiriego_28.jpg",
      "assets/img/cocina/Restaurante_Quiriego_29.jpg",
      "assets/img/cocina/Restaurante_Quiriego_30.jpg"
    ];
    let imageItems = images.map((image, i) => {
      return (
        <div className="ms-item">
          <img src={image} alt="" />
          <a
            onClick={() => this.setState({ isOpen: true, photoIndex: i })}
            title=""
            className="html5lightbox zoom-img-2"
          >
            <img src="assets/images/zoom.png" alt="" />
          </a>
        </div>
      );
    });
    const { photoIndex, isOpen } = this.state;
    return (
      <div>
        <Helmet>
          <meta
            name="description"
            content="Restaurante Quiriego, en el corazón de Hermosillo, ofrece una experiencia culinaria inolvidable. Ambiente relajado, con servicio de desayuno, comida y cena. Visítanos."
          />
          <meta
            property="og:title"
            content="Restaurante Quiriego | Cocina Rustica | Experiencia Culinaria
            Inigualable"
          />
          <meta property="og:type" content="article" />
          <meta
            property="og:url"
            content="https://cocky-bhaskara-cb37ab.netlify.com/restaurante-quiriego"
          />
          <meta
            property="og:image"
            content="https://cocky-bhaskara-cb37ab.netlify.com/assets/img/logo-marsella45-hermosillo.png"
          />
          <title>
            Restaurante Quiriego | Cocina Rustica | Experiencia Culinaria
            Inigualable
          </title>
        </Helmet>
        <section className="pager-sec cocina-sec">
          <div className="container">
            <div className="pager-sec-details">
              <h3>QUIRIEGO</h3>
              <ul>
                <li>
                  <Link to="/home">{Lang().generico.inicio[this.state.language]}</Link>
                </li>
                <li>
                  <span>{Lang().cocina.cocinaRustica[this.state.language]}</span>
                </li>
              </ul>
            </div>
          </div>
        </section>

        <section className="c-block pb-0">
          <div className="container">
            <div className="sec-title">
              <h3>{Lang().cocina.unaExperienciaInolvidable[this.state.language]}</h3>
            </div>

            <div className="rest_head">
              <div className="row">
                <div className="col-lg-12">
                  <p>
                  {Lang().cocina.cocinaDesc[this.state.language]}
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="card-columns mb-5">{imageItems}</div>
            </div>
          </div>
        </section>
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length
              })
            }
          />
        )}
      </div>
    );
  }
}

export default CocinaRustica;
