import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import AmenidadesA from "../common/AmenidadesA";
import JuniorMed from "../suites/JuniorMed";
import MasterMed from "../suites/MasterMed";
import LuxuryMed from "../suites/LuxuryMed";
import JuniorSm from "../suites/JuniorSm";
import MasterSm from "../suites/MasterSm";
import LuxurySm from "../suites/LuxurySm";
import NecesitasAyuda from "../suites/NecesitasAyuda";
import Slider from "react-slick";
import Flatpickr from "react-flatpickr";
import Lightbox from "react-image-lightbox";
import Lang from "../../lang";

class JuniorSuiteDoble extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
      photoIndex: 0,
      isOpen: false
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });

    let _lang = localStorage.getItem("marsellaLang")
    let _langu = localStorage.getItem("marsellaLangu")
    if (_lang && _langu) {
      this.setState({ language: _lang, changeLang: _langu})
    } else {
      localStorage.setItem("marsellaLang", Lang().default)
      localStorage.setItem("marsellaLangu", Lang().btnDefault)
      this.setState({ language: Lang().default, changeLang: Lang().btnDefault})
    }
  }
  componentWillMount() {
    function loadScript(src) {
      return new Promise(function(resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function() {
          resolve();
        });
        script.addEventListener("error", function(e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }
    loadScript("/assets/js/handleCounter.js");
  }
  render() {
    const images = [
      "assets/img/jr-suite-doble/jr-suite-doble-hermosillo-2.jpg",
      "assets/img/jr-suite-doble/jr-suite-doble-hermosillo-4.jpg",
      "assets/img/jr-suite-doble/jr-suite-doble-hermosillo-6.jpg",
      "assets/img/jr-suite-doble/jr-suite-doble-hermosillo-8.jpg",
      "assets/img/jr-suite-doble/jr-suite-doble-hermosillo-10.jpg",
      "assets/img/jr-suite-doble/jr-suite-doble-hermosillo-12.jpg",
      "assets/img/jr-suite-doble/jr-suite-doble-hermosillo-14.jpg",
      "assets/img/jr-suite-doble/jr-suite-doble-hermosillo-16.jpg",
      "assets/img/jr-suite-doble/jr-suite-doble-hermosillo-18.jpg",
      "assets/img/jr-suite-doble/jr-suite-doble-hermosillo-20.jpg"
    ];
    const images2 = [
      "assets/img/jr-suite-doble/jr-suite-doble-hermosillo-21.jpg",
      "assets/img/jr-suite-doble/jr-suite-doble-hermosillo-22.jpg",
      "assets/img/jr-suite-doble/jr-suite-doble-hermosillo-23.jpg",
      "assets/img/jr-suite-doble/jr-suite-doble-hermosillo-24.jpg",
      "assets/img/jr-suite-doble/jr-suite-doble-hermosillo-25.jpg",
      "assets/img/jr-suite-doble/jr-suite-doble-hermosillo-26.jpg",
      "assets/img/jr-suite-doble/jr-suite-doble-hermosillo-27.jpg",
      "assets/img/jr-suite-doble/jr-suite-doble-hermosillo-28.jpg",
      "assets/img/jr-suite-doble/jr-suite-doble-hermosillo-29.jpg",
      "assets/img/jr-suite-doble/jr-suite-doble-hermosillo-30.jpg"
    ];
    let imageItems1 = images.map((image, i) => {
      return (
        <div class="rom_img_slide">
          <img src={image} alt="" />
          <a
            onClick={() => this.setState({ isOpen: true, photoIndex: i })}
            title=""
            class="html5lightbox zoom-img"
          >
            <img src="assets/images/zoom.png" alt="" />
          </a>
        </div>
      );
    });
    let imageItems2 = images2.map(image => {
      return (
        <li>
          <div class="thumb_img">
            <img src={image} alt="" />
          </div>
        </li>
      );
    });
    const { photoIndex, isOpen } = this.state;
    return (
      <div>
        <Helmet>
          <meta
            name="description"
            content="Habitación Doble Junior Suite. Más espacio, mayor comfort y la experiencia de un hospedaje de ensueño único en Hermosillo."
          />
          <meta
            property="og:title"
            content="Habitación Junior Suite Doble en Hermosillo | Marsella 45"
          />
          <meta property="og:type" content="article" />
          <meta
            property="og:url"
            content="https://cocky-bhaskara-cb37ab.netlify.com/junior-suite-doble"
          />
          <meta
            property="og:image"
            content="https://cocky-bhaskara-cb37ab.netlify.com/assets/img/logo-marsella45-hermosillo.png"
          />
          <title>
            Habitación Junior Suite Doble en Hermosillo | Marsella 45
          </title>
        </Helmet>
        <section class="pager-sec jr-doble-top">
          <div class="container">
            <div class="pager-sec-details">
              <h3>JUNIOR SUITE DOBLE</h3>
              <ul>
                <li>
                  <Link to="/home">{Lang().generico.inicio[this.state.language]}</Link>
                </li>
                <li>
                  <span>JUNIOR SUITE DOBLE</span>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section className="main-content">
          <div className="container">
            <div className="page-content">
              <div className="room-single-page">
                <div className="row">
                  <div class="col-lg-8">
                    <div class="room-single-details">
                      <div class="room_slide_sec">
                        <Slider
                          className="room_slide_imgs"
                          asNavFor={this.state.nav2}
                          ref={slider => (this.slider1 = slider)}
                        >
                          {imageItems1}
                        </Slider>

                        <Slider
                          className="room_slide_thumbs"
                          asNavFor={this.state.nav1}
                          ref={slider => (this.slider2 = slider)}
                          slidesToShow={4}
                          swipeToSlide={true}
                          focusOnSelect={true}
                          arrows={false}
                        >
                          {imageItems2}
                        </Slider>
                      </div>

                      <div class="room_descp_sec">
                        <ul class="dep-head">
                          <li>
                            <img src="assets/images/c1.png" alt="" />
                            <span>{Lang().habitaciones.juniorDoble.camasQueen[this.state.language]}</span>
                          </li>
                          <li>
                            <img src="assets/images/c2.png" alt="" />
                            <span>2 {Lang().generico.adultos[this.state.language]} 4 {Lang().generico.ninos[this.state.language]} </span>
                          </li>
                          <li>
                            <img src="assets/images/c3.png" alt="" />
                            <span>54m²</span>
                          </li>
                          <li>
                            <img src="assets/images/c4.png" alt="" />
                            <span>{Lang().habitaciones.vistaJardin[this.state.language]}</span>
                          </li>
                        </ul>

                        <div class="clearfix"></div>
                        <div class="descp_info">
                          <h3>{Lang().habitaciones.descripcion[this.state.language]}</h3>
                          <p>
                          {Lang().habitaciones.juniorDoble.desc[this.state.language]}
                          </p>
                        </div>

                        <div class="descp_info">
                          <h3>{Lang().generico.servicios[this.state.language]}</h3>
                          <ul class="svx-list">
                            <li>{Lang().habitaciones.juniorDoble.camasQueen[this.state.language]}</li>
                            <li>{Lang().habitaciones.juniorServicios.s2[this.state.language]}</li>
                            <li>{Lang().habitaciones.juniorServicios.s3[this.state.language]}</li>
                            <li>{Lang().habitaciones.juniorServicios.s4[this.state.language]}</li>
                            <li>{Lang().habitaciones.juniorServicios.s5[this.state.language]}</li>
                            <li>{Lang().habitaciones.juniorServicios.s6[this.state.language]}</li>
                            <li>{Lang().habitaciones.juniorServicios.s7[this.state.language]}</li>
                            <li>{Lang().habitaciones.juniorServicios.s8[this.state.language]}</li>
                            <li>{Lang().habitaciones.juniorServicios.s9[this.state.language]}</li>
                            <li>{Lang().habitaciones.juniorServicios.s10[this.state.language]}</li>
                            <li>{Lang().habitaciones.juniorServicios.s11[this.state.language]}</li>
                            <li>{Lang().habitaciones.juniorServicios.s12[this.state.language]}</li>
                            <li>{Lang().habitaciones.juniorServicios.s13[this.state.language]}</li>
                            <li>{Lang().habitaciones.juniorServicios.s14[this.state.language]}</li>
                            <li>{Lang().habitaciones.juniorServicios.s15[this.state.language]}</li>
                            <li>{Lang().habitaciones.juniorServicios.s16[this.state.language]}</li>
                            <li>{Lang().habitaciones.juniorServicios.s17[this.state.language]}</li>
                            <li>{Lang().habitaciones.juniorServicios.s18[this.state.language]}</li>
                            <li>{Lang().habitaciones.juniorServicios.s19[this.state.language]}</li>
                            <li>{Lang().habitaciones.juniorServicios.s20[this.state.language]}</li>
                            <li>{Lang().habitaciones.juniorServicios.s21[this.state.language]}</li>
                            <li>{Lang().habitaciones.juniorServicios.s22[this.state.language]}</li>
                            <li>{Lang().habitaciones.juniorServicios.s23[this.state.language]}</li>
                            <li>{Lang().habitaciones.juniorServicios.s24[this.state.language]}</li>
                            <li>{Lang().habitaciones.juniorServicios.s25[this.state.language]}</li>
                            <li>{Lang().habitaciones.juniorServicios.s26[this.state.language]}</li>
                            <li>{Lang().habitaciones.juniorServicios.s27[this.state.language]}</li>
                            <li>{Lang().habitaciones.juniorServicios.s28[this.state.language]}</li>
                            <li>{Lang().habitaciones.juniorServicios.s29[this.state.language]}</li>
                            <li>{Lang().habitaciones.juniorServicios.s30[this.state.language]}</li>
                            <li>{Lang().habitaciones.juniorServicios.s31[this.state.language]}</li>
                            <li>{Lang().habitaciones.juniorServicios.s32[this.state.language]}</li>
                            <li>{Lang().habitaciones.juniorServicios.s33[this.state.language]}</li>
                          </ul>
                          <div class="clearfix"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="sidebar">
                      <div className="avail-sec mgb-30">
                        <form
                          action="https://app.thebookingbutton.com/properties/marsella45direct"
                          class="ibe"
                          data-region="emea"
                          data-channelcode="marsella45direct"
                          target="_blank"
                        >
                          <div class="checking-form">
                            <ul class="checkform">
                              <li>
                                <div class="check-form">
                                  <h4>CHECK-IN</h4>
                                  <Flatpickr
                                    value={new Date()}
                                    onChange={(_, str) => console.info(str)}
                                    name="check_in_date"
                                  />
                                  <input
                                    type="hidden"
                                    name="room_type"
                                    value="123498"
                                  />
                                  <a href="#" title="" class="arw_down">
                                    <i class="fa fa-angle-down"></i>
                                  </a>
                                  <div class="clearfix"></div>
                                </div>
                              </li>
                              <li class="item-cont">
                                <div class="check-form">
                                  <h4>{Lang().generico.noches[this.state.language]}</h4>
                                  <div class="det">
                                    <div
                                      class="handle-counter"
                                      id="handleCounter"
                                    >
                                      <ul>
                                        <li class="input-fieldd">
                                          <input
                                            type="number"
                                            name="number_nights"
                                            min="1"
                                            value="1"
                                          />
                                        </li>
                                        <li class="counter-control">
                                          <button
                                            type="button"
                                            class="counter-plus btn"
                                            disabled=""
                                          >
                                            <i class="fa fa-angle-up"></i>
                                          </button>
                                          <button
                                            type="button"
                                            class="counter-minus btn"
                                            disabled=""
                                          >
                                            <i class="fa fa-angle-down"></i>
                                          </button>
                                        </li>
                                      </ul>
                                      <div class="clearfix"></div>
                                    </div>
                                  </div>
                                  <div class="clearfix"></div>
                                </div>
                              </li>
                              <li class="item-cont">
                                <div class="check-form">
                                  <h4>{Lang().generico.adultos[this.state.language]}</h4>
                                  <div class="det">
                                    <div
                                      class="handle-counter"
                                      id="handleCounter2"
                                    >
                                      <ul>
                                        <li class="input-fieldd">
                                          <input
                                            type="number"
                                            value="2"
                                            max="7"
                                            min="1"
                                            name="number_adults"
                                          />
                                        </li>
                                        <li class="counter-control">
                                          <button
                                            type="button"
                                            class="counter-plus btn"
                                            disabled=""
                                          >
                                            <i class="fa fa-angle-up"></i>
                                          </button>
                                          <button
                                            type="button"
                                            class="counter-minus btn"
                                            disabled=""
                                          >
                                            <i class="fa fa-angle-down"></i>
                                          </button>
                                        </li>
                                      </ul>
                                      <div class="clearfix"></div>
                                    </div>
                                  </div>
                                  <div class="clearfix"></div>
                                </div>
                              </li>
                              <li class="item-cont">
                                <div class="check-form">
                                  <h4>{Lang().generico.ninos[this.state.language]}</h4>
                                  <div class="det">
                                    <div
                                      class="handle-counter"
                                      id="handleCounter3"
                                    >
                                      <ul>
                                        <li class="input-fieldd">
                                          <input
                                            type="number"
                                            value="0"
                                            name="number_children"
                                            max="3"
                                            min="0"
                                          />
                                        </li>
                                        <li class="counter-control">
                                          <button
                                            type="button"
                                            class="counter-plus btn"
                                            disabled=""
                                          >
                                            <i class="fa fa-angle-up"></i>
                                          </button>
                                          <button
                                            type="button"
                                            class="counter-minus btn"
                                            disabled=""
                                          >
                                            <i class="fa fa-angle-down"></i>
                                          </button>
                                        </li>
                                      </ul>
                                      <div class="clearfix"></div>
                                    </div>
                                  </div>
                                  <div class="clearfix"></div>
                                </div>
                              </li>
                              <li class="check-avail">
                                <button class="check-status" type="submit">
                                {Lang().generico.verDisponibilidad[this.state.language]}
                                </button>
                              </li>
                            </ul>
                            <div class="clearfix"></div>
                          </div>
                        </form>
                      </div>

                      <div className="widget widget_posts">
                        <h3 className="widget_title">{Lang().habitaciones.nuestrasSuites[this.state.language]}</h3>
                        <ul>
                          <LuxurySm />
                          <MasterSm />
                          <JuniorSm />
                        </ul>
                      </div>
                      <NecesitasAyuda />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <AmenidadesA />
        <section className="c-block pt-0">
          <div className="container">
            <div className="sec-title text-left mgb-30">
              <h3>{Lang().habitaciones.nuestrasSuites[this.state.language]}</h3>
            </div>

            <div className="similar-rooms">
              <div className="row">
                <LuxuryMed />
                <MasterMed />
                <JuniorMed />
              </div>
            </div>
          </div>
        </section>
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length
              })
            }
          />
        )}
      </div>
    );
  }
}
export default JuniorSuiteDoble;
