import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import AmenidadesA from '../common/AmenidadesA';
import Lang from "../../lang";

class Servicios extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
    }; 
  }
  
  componentDidMount() {
    let _lang = localStorage.getItem("marsellaLang")
    let _langu = localStorage.getItem("marsellaLangu")
    if (_lang && _langu) {
      this.setState({ language: _lang, changeLang: _langu})
    } else {
      localStorage.setItem("marsellaLang", Lang().default)
      localStorage.setItem("marsellaLangu", Lang().btnDefault)
      this.setState({ language: Lang().default, changeLang: Lang().btnDefault})
    }
  }

  render() {
    return (
      <div>
        <Helmet>
          <meta
            name="description"
            content="Marsella 45 abraza cada uno de los sentidos con su aroma, su música ambiental, las sábanas de 800 hilos, su gastronomía y el refinado buen gusto en su interiorismo"
          />
          <meta
            property="og:title"
            content="Servicios y Amenidades | Marsella 45 Casa Abeyta"
          />
          <meta property="og:type" content="article" />
          <meta
            property="og:url"
            content="https://cocky-bhaskara-cb37ab.netlify.com/servicios"
          />
          <meta
            property="og:image"
            content="https://cocky-bhaskara-cb37ab.netlify.com/assets/img/logo-marsella45-hermosillo.png"
          />
          <title>{Lang().serviciosPagina.serviciosYAmenidadesTitle[this.state.language]}</title>
        </Helmet>

        <section class="pager-sec servicios-top">
          <div class="container">
            <div className="pager-sec-details">
              <h3>{Lang().serviciosPagina.servciosEInstalaciones[this.state.language]}</h3>
              <ul>
                <li>
                  <Link to="/home">Inicio</Link>
                </li>
                <li>
                  <span>{Lang().serviciosPagina.servciosEInstalaciones[this.state.language]}</span>
                </li>
              </ul>
            </div>
          </div>
        </section>

        <section class="c-block white-bg">
          <div class="container">
            <div class="about-us-sec about-pg">
              <div class="row">
                <div class="col-lg-5">
                  <div class="about_we">
                    <h2>
                    {Lang().serviciosPagina.conoceNuestrasInstalaciones[this.state.language]}
                    </h2>
                    <p>
                    {Lang().serviciosPagina.parrafo1[this.state.language]}
                    </p>
                    <p>
                    {Lang().serviciosPagina.parrafo2[this.state.language]}
                    </p>
                    <p>
                    {Lang().serviciosPagina.parrafo3[this.state.language]}
                    </p>
                  </div>
                </div>
                <div class="col-lg-7 p-0">
                  <div class="container">
                    <div class="resot_sec" style={{ marginTop: '50px' }}>
                      <div class="row">
                        <div class=" col-md-6 col-sm-6">
                          <div class="resot-dv">
                            <div class="resot-icon">
                              <i class="la la-wifi"></i>
                            </div>
                            <h3>Internet</h3>
                          </div>
                        </div>
                        <div class=" col-md-6 col-sm-6">
                          <div class="resot-dv">
                            <div class="resot-icon">
                              <i class="fab fa-black-tie"></i>
                            </div>
                            <h3>{Lang().serviciosPagina.lavanderia[this.state.language]}</h3>
                          </div>
                        </div>
                        <div class=" col-md-6 col-sm-6">
                          <div class="resot-dv">
                            <div class="resot-icon">
                              <i class="la la-star"></i>
                            </div>
                            <h3>{Lang().serviciosPagina.valetParking[this.state.language]}</h3>
                          </div>
                        </div>
                        <div class=" col-md-6 col-sm-6">
                          <div class="resot-dv">
                            <div class="resot-icon">
                              <i class="la la-car"></i>
                            </div>
                            <h3>{Lang().serviciosPagina.estacionamiento[this.state.language]}</h3>
                          </div>
                        </div>
                        <div class=" col-md-6 col-sm-6">
                          <div class="resot-dv">
                            <div class="resot-icon">
                              <i
                                class="la la-lock"
                                style={{ top: '2px', left: '2px' }}
                              ></i>
                            </div>
                            <h3>{Lang().serviciosPagina.cajaSeguridad[this.state.language]}</h3>
                          </div>
                        </div>
                        <div class=" col-md-6 col-sm-6">
                          <div class="resot-dv">
                            <div class="resot-icon">
                              <i
                                class="la la-coffee"
                                style={{ top: '11x', right: '6px' }}
                              ></i>
                            </div>
                            <h3>{Lang().serviciosPagina.servicioCuartos[this.state.language]}</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <AmenidadesA />
      </div>
    );
  }
}
export default Servicios;
